.stepper-navigation {
    margin-bottom: 3.6rem;
    background: $color-gray-100;
    border-bottom: 1px solid $color-gray-300;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        margin-bottom: 6.5rem;
    }
}

.steps-navigation {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    @include media-breakpoint-up(md) {
        display: flex;
        max-width: 100.8rem;
        margin: 0 auto;
        padding: 3.6rem 0;
        justify-content: space-around;
    }
    .step {
        position: relative;
        display: none;
        padding: 1.6rem 0;
        list-style: none;
        color: $color-purple;
        font-weight: $font-weight-bold;
        text-align: left;
        cursor: pointer;
        @include media-breakpoint-up(md) {
            display: block;
            flex: 1 1 auto;
            flex-basis: 0;
            padding: 0;
            color: $color-gray-700;
            text-align: center;
            cursor: default;
            &::after {
                position: absolute;
                top: 2.4rem;
                left: 50%;
                height: 2px;
                width: 50%;
                content: "";
                background: $color-gray-600;
                z-index: 1;
            }
            &::before {
                position: absolute;
                top: 2.4rem;
                left: 0;
                height: 2px;
                width: 50%;
                content: "";
                background: $color-gray-600;
                z-index: 1;
            }
            &:first-child::before {
                display: none;
            }
            &:last-child::after {
                display: none;
            }
        }
        &.active {
            display: block;
        }
        .icon-wrapper {
            position: relative;
            color: $color-gray-600;
            @include media-breakpoint-up(md) {
                display: block;
                padding-top: .9rem;
                margin: 0 auto 1rem;
                width: 4.8rem;
                height: 4.8rem;
                border: 2px solid $color-gray-600;
                background: $color-gray-100;
                @include border-radius(50%);
                z-index: 2;
            }

            .icon {
                color: $color-purple;
                @include media-breakpoint-up(md) {
                    color: $color-gray-600;
                }
            }
        }
        .badge {
            float: right;
        }
        @include media-breakpoint-up(md) {
            &.active {
                color: $color-purple;
                .icon-wrapper {
                    background: $color-purple;
                    border-color: $color-purple;
                    .icon {
                        color: $color-white;
                    }
                }
                &::before {
                    background: $color-purple;
                }
            }
            &.completed {
                .icon-wrapper {
                    border-color: $color-purple;
                    transition: background-color .24s;
                    .icon {
                        color: $color-purple;
                        transition: color .24s;
                    }
                }
                &::before, &::after {
                    background: $color-purple;
                }
                a {
                    display: block;
                    color: $color-purple;
                    &:hover .icon-wrapper {
                        background: $color-purple;
                        .icon {
                            color: $color-white;
                        }
                    }
                }
            }
        }

    }
}

.mobile-stepper-toggle {
    position: absolute;
    top: 0;
    left: -15px;
    right: -15px;
    height: 100%;
    z-index: 10;
    cursor: pointer;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

/* mobile steps */
.stepper-mobile {
    padding-bottom: 2.4rem;
    background: $color-gray-100;
    @include media-breakpoint-up(md) {
        display: none !important;
    }
    .mobile-step {
        display: block;
        padding: 1.6rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        border: 2px solid $color-gray-500;
        border-top: 0;
        &:first-child {
            border-top: 2px solid $color-gray-500;
            border-radius: .4rem .4rem 0 0;
        }
        &:last-child {
            border-radius: 0 0 .4rem .4rem;
        }
        &.active {
            background: $color-violet;
            color: $color-lightest;
            border-color: $color-violet;
        }
        &.completed {
            border-color: $color-violet;
        }
    }
}
