/*
Background

Easily set the background of an element to any contextual class. Anchor components will darken on hover, just like the text classes. Background utilities do not set color, so in some cases you’ll want to use .text-* utilities.

Markup: docs/utilities/background.hbs

Style guide: Utilities.background
*/

//
// Contextual backgrounds
//

.bg-faded {
    background-color: darken($color-body-bg, 3%);
}

@include bg-variant('.bg-white', $color-white);

@include bg-variant('.bg-gray-100', $color-gray-100);

@include bg-variant('.bg-primary', $color-primary);

@include bg-variant('.bg-default', $color-default);

@include bg-variant('.bg-primary-lighter', $color-primary-lighter);

@include bg-variant('.bg-neutral', $color-gray-lightest);

@include bg-variant('.bg-success', $color-success);

@include bg-variant('.bg-info', $color-info);

@include bg-variant('.bg-warning', $color-warning);

@include bg-variant('.bg-danger', $color-danger);

@include bg-variant('.bg-inverse', $color-inverse);

