/*
Button

Use custom button styles for actions in forms, dialogs, and more. Includes support for a handful of contextual variations, sizes, states, and more.

Markup: docs/core/button.hbs

Style guide: Core.button
*/

//
// Base styles
//

.btn {
    display: inline-block;
    font-weight: $btn-font-weight;
    text-align: center;
    border: $btn-border-width solid transparent;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius-sm);
    @include transition($btn-transition);
    text-decoration: none;
    // Share hover and focus styles
    @include hover-focus {
        text-decoration: none;
    }
    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        cursor: $cursor-disabled;
        opacity: .65;
        @include box-shadow(none);
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
        background-image: none;
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
    &.has-icon {
        position: relative;
        padding-right: $btn-icon-offset;
        .btn-icon {
            position: absolute;
            right: 1.2rem;
            top: 50%;
            color: inherit;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        &.left {
            padding-right: $btn-padding-x;
            padding-left: $btn-icon-offset;
            .btn-icon {
                left: 1.2rem;
                right: auto;
            }
        }
    }
    &.icon-only {
        padding-top: 1.2rem;
        padding-bottom: 1rem;
    }
    &.icon-only-md {
        @include media-breakpoint-up(md) {
            padding: 1.2rem $btn-padding-x 1rem !important;
            .icon {
                position: static !important;
                transform: none !important;
            }
        }
    }
}

.btn-100-sm-down {
    @include media-breakpoint-down(xs) {
        width: 100%;
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none;
}

.btn-action {
    position: relative;
    padding-left: 6rem;
    background: transparent;
    @include border-radius(0);
    .btn-action-icon {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: $action-btn-icon-size;
        height: $action-btn-icon-size;
        line-height: $action-btn-icon-line-height;
        border-width: $action-btn-border-width;
        border-style: solid;
        @include border-radius(50%);
        color: $color-lightest;
        transform: translateY(-50%);
        transition: background-color .24s, border-color .24s, opacity .24s;
        .action-icon-outline & {
            background: transparent;
        }
    }

}

@each $color, $value in $action-btn-colors {
    .btn-action-#{$color} {
        color: map-get($value, color);
        .btn-action-icon {
            @if $color == "white" {
                color: $color-violet;
            }
            background-color: map-get($value, color);
            border-color: map-get($value, color);

        }
        &.is-outline .btn-action-icon {
            background-color: transparent;
            color: map-get($value, color);
        }
        /* give hover when card is hovered */
        .js-card-clickable:hover &,
        &:hover,
        &:focus {
            @if $color == "white" {
                opacity: .85;
            }
            color: map-get($value, color-dark);
            .btn-action-icon {
                @if $color == "white" {
                    opacity: .85;
                }
                border-color: map-get($value, color-dark);
                background-color: map-get($value, color-dark);
            }
            &.is-outline .btn-action-icon {
                background-color: transparent;
                color: map-get($value, color-dark);
            }
        }
        .action-icon-outline & {
            color: map-get($value, color);
        }
        &.action-icon-outline {
            background: transparent;
            color: $color-violet;
        }

    }
}


.btn-default {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}


.btn-negative {
    @include button-variant($btn-negative-color, $btn-negative-bg, $btn-negative-border);
}

.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
.btn-secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}
.btn-info {
    @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
.btn-success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
.btn-warning {
    @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
.btn-danger {
    @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Remove all backgrounds
.btn-outline-primary {
    @include button-outline-variant($btn-primary-bg);
    &:hover, &:active {
        background: transparent;
        color: $color-primary-dark;
        border-color: $color-primary-dark;
    }
}
.btn-outline-default {
    color: $color-gray-600;
    border-color: $color-gray-600;
    &:hover {
        color: $color-gray-700;
        border-color: $color-gray-700;
    }
}

.btn-outline-secondary {
    @include button-outline-variant($btn-secondary-border);
    &:hover, &:active {
        color: $color-primary;
        border-color: $color-primary;
    }
}

.btn-lightest {
    color: $color-lightest;
    background: transparent;
    border-color: $color-lightest;
    &:hover, &:active {
        color: $color-primary;
        background: $color-lightest;
        border-color: $color-lightest;
    }
    &:hover .icon {
        background-position-y: -40px;
    }
}


.btn-outline-info {
    @include button-outline-variant($btn-info-bg);
    &:hover .icon {
        background-position-y: -340px;
    }
}
.btn-outline-success {
    @include button-outline-variant($btn-success-bg);
    border-color: $color-success-dark;
    &:hover, &:active {
        background-color: $color-success-dark;
        border-color: $color-success-dark;
        .icon {
            background-position-y: -340px;
        }
    }

}
.btn-outline-warning {
    @include button-outline-variant($btn-warning-bg);
    &:hover .icon {
        background-position-y: -340px;
    }
}
.btn-outline-danger {
    @include button-outline-variant($btn-danger-bg);
    &:hover, &:active {
        background: $color-danger;
        .icon {
            background-position-y: -340px;
        }
    }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    padding: 0;
    font-weight: $font-weight-normal;
    text-decoration: underline;
    color: $color-link;
    border-radius: 0;
    cursor: pointer;
    &,
    &:active,
    &.active,
    &:disabled {
        background-color: transparent;
        @include box-shadow(none);
    }
    &,
    &:focus,
    &:active {
        border-color: transparent;
    }
    @include hover {
        border-color: transparent;
    }
    @include hover-focus {
        text-decoration: $decoration-link-hover;
        color: $color-link-hover;
        background-color: transparent;
    }
    &:disabled {
        color: $btn-link-disabled-color;

        @include hover-focus {
            text-decoration: none;
        }
    }
}

.btn-add-ean {
    padding: 0;
    text-decoration: none;
    font-weight: 700;
    transition: color .24s;
    .icon {
        color: inherit
    }
}

//
// Icon buttons
//

.btn-icon {
    padding: $btn-icon-padding-y $btn-icon-padding-x;
}

//
// Button Sizes
//

.btn-lg {
    position: relative;
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, 1.6rem, 1.6rem, $btn-border-radius);
    padding-right: 40px;
    .icon {
        position: absolute;
        top: 13px;
        right: 12px;
    }
}
.btn-sm {
    // line-height: ensure proper height of button next to small input
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, 1.7rem, $font-size-sm, $btn-border-radius-sm);
}

.collapse-toggler {
    &[aria-expanded="true"] {
        .expanded-true {
            display: inline-block;
        }
        .expanded-false {
            display: none;
        }
    }
    &[aria-expanded="false"] {
        .expanded-true {
            display: none;
        }
        .expanded-false {
            display: inline-block;
        }
    }
}


//
// Block button
//

.btn-block {
    display: block;
    width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
    margin-top: $btn-block-spacing-y;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}

.btn.has-icon {
    position: relative;
    padding-right: 45px;
    .icon {
        position: absolute;
        top: 11px;
        right: 10px;
    }
    &.btn-sm {
        .icon {
            top: 7px;
            right: 5px;
        }
    }
}


///* edit buttons (much difference from others */
//.btn-outline-edit {
//    top: 7px !important;
//    height: 30px;
//    padding: 6px 3px !important;
//    background-color: transparent;
//    border-color: $color-lightest;
//    .icon {
//        border: none;
//    }
//    &:hover {
//        background-color: $color-secondary-dark;
//    }
//
//}
//
//.btn-outline-default.icon-only {
//    top: 7px !important;
//    height: 30px;
//    padding: 6px 3px !important;
//    background-color: transparent;
//    border-width: 1px;
//    .icon {
//        border: none;
//    }
//    &:hover {
//        background-color: #968E88;
//    }
//}

/*
	-----------------------------------------
	Btn file
	-----------------------------------------
*/

.btn-file {
    position: relative;
    padding-top: 9px;
    overflow: hidden;
}
.btn-file input[type=file] {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    background: white;
    outline: none;
    cursor: inherit;
    opacity: 0;
    filter: alpha(opacity=0);
}
