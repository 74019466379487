/*
 Footer
 */

// Footer --> primary footer
//

.footer-primary {
    position: relative;
    padding-top: 16.875rem;
    padding-bottom: 2.4rem;
    font-size: 1.5rem;
    background-image: url('../assets/svg/img-bg-footer.svg');
    background-repeat: no-repeat;
    background-position: center 11rem;
    background-size:  1800px auto;
    border-top-color: transparent;
    &, a {
        line-height: 1.5;
    }
    a {
        display: inline-block;
        margin-top: .4rem;
        text-decoration: underline;
        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &:before {
        position: absolute;
        top: 196px;
        left: 0;
        width: 100%;
        height: 3px;
        content: '';
        background: #B7B7B7;
        z-index: -1;
    }

}

.footer-gray-100 .footer-primary {
    background-color: $color-gray-100;
}
