/*
Dropdown

Dropdowns are toggleable, contextual overlays for displaying lists of links and more. They’re made interactive with the dropdown JavaScript plugin. They’re toggled by clicking, not by hovering; this is an intentional design decision.

Markup: docs/core/dropdown.hbs

Style guide: Core.dropdown
*/

// The dropdown wrapper (`<div>`)
.dropup,
.dropdown {
    position: relative;

    .btn-block {
        text-align: left;
    }
}

.dropdown-toggle {
    // Generate the caret automatically
    &::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: $caret-width;
        content: "";
        border-top: $caret-width solid;
        border-left: $caret-width solid transparent;
        border-right: $caret-width solid transparent;
        vertical-align: middle;
    }

    // Prevent the focus on the dropdown toggle when closing dropdowns
    &:focus {
        outline: 0;
    }
}

.dropup {
    .dropdown-toggle {
        &::after {
            border-top: 0;
            border-bottom: $caret-width solid;
        }
    }
}

// The dropdown menu
.dropdown-menu {
    display: none; // none by default, but block on "open" of the menu
    position: absolute;
    top: 100%;
    left: 0;
    float: left;
    min-width: $dropdown-min-width;
    margin: $dropdown-margin-top 0 0; // override default ul
    padding: $dropdown-padding-y 0;
    font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
    list-style: none;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    color: $color-body-text;
    background-clip: padding-box;
    background-color: $dropdown-bg;
    border: $dropdown-border-width solid $dropdown-border-color;
    z-index: $zindex-dropdown;
    @include box-shadow($dropdown-box-shadow);
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
    @include nav-divider($dropdown-divider-bg);
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
    display: block;
    clear: both;
    width: 100%; // For `<button>`s
    padding: 3px $dropdown-item-padding-x;
    font-weight: $font-weight-normal;
    text-align: inherit; // For `<button>`s
    color: $dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    background: none; // For `<button>`s
    border: 0; // For `<button>`s

    @include hover-focus {
        text-decoration: none;
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
    }

    &.active,
    &:active {
        text-decoration: none;
        color: $dropdown-link-active-color;
        background-color: $dropdown-link-active-bg;
    }

    &.disabled,
    &:disabled {
        color: $dropdown-link-disabled-color;
        background-color: transparent;
        cursor: $cursor-disabled;
        // Remove CSS gradients if they're enabled
        @if $enable-gradients {
            background-image: none;
        }
    }
}

// Open state for the dropdown
.show {
    // Show the menu
    > .dropdown-menu {
        display: block;
    }

    // Remove the outline when :focus is triggered
    > a {
        outline: 0;
    }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
    left: auto; // Reset the default from `.dropdown-menu`
    right: 0;
}

.dropdown-menu-left {
    left: 0;
    right: auto;
}

// Dropdown section headers
.dropdown-header {
    display: block;
    margin-bottom: 0; // for use with heading elements
    padding: $dropdown-padding-y $dropdown-item-padding-x;
    font-size: $font-size-sm;
    color: $dropdown-header-color;
    white-space: nowrap; // as with > li > a
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zindex-dropdown-backdrop;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set.

.dropup {
    // Different positioning for bottom up menu
    .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-bottom: $dropdown-margin-top;
    }
}
