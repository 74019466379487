/*
Nav

Navigations general markup and styles, from the base .nav class to the active and disabled states. Swap modifier classes to switch between each style.

Markup: docs/components/nav.hbs

Style guide: Components.nav
*/

/* NAV: Base class
========================================================== */
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: $nav-link-padding;
    text-decoration: none;
    @include hover-focus {
        text-decoration: none;
    }

    // Disabled state lightens text and removes hover/tab effects
    &.disabled {
        color: $nav-disabled-link-color;
        cursor: $cursor-disabled;
    }
}

// Nav lang
//
.header-nav {
    float: right;
    width: auto;
    margin-top: 1.7rem;
    padding: 0;
    text-align: left;
    border-top: 0;
    .nav-link {
        display: inline-block;
        margin: 0 0 0 2.4rem;
        padding: 0;
        font-family: $font-family-base;
        font-weight: $font-weight-normal;
        font-size: 1.6rem;
        text-decoration: none;
        color: $color-white;
        &:hover,&:focus {
            color: $color-purple-dark;
        }
    }
    .nav-items {
        margin: 0 0 0 1.9rem;
        padding: 0;
    }
    .nav-item {
        display: inline-block;
        text-transform: uppercase;
        .nav-link {
            width: 3rem;
            height: 3rem;
            margin: 0;
            padding: 0;
            line-height: 3rem;
            font-weight: $font-weight-bold;
            font-size: 1.4rem;
            text-align: center;
            border-radius: 50%;
        }
    }
    .nav-item.active {
        .nav-link {
            color: $color-violet;
            background: $color-white;
        }
    }
}

//
// Nav primary
//

.nav-primary {
    background-color: $color-gray-200;
    max-height: 71px;
    @include clearfix();
    .container {
        padding-bottom: 0;
        @include clearfix();
        @include media-breakpoint-down(md) {
            padding: 0;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
            max-width: 100%;
        }
        .navbar-collapse {
            @include media-breakpoint-up(lg) {
                display: block;
                background: #F3F3F3;
            }
        }
    }
    .navbar-header {
        text-align: left;
        .navbar-toggler {
            margin-top: -3px;
        }
    }
    .nav {
        display: block;
        text-align: center;
        width: 100%;
    }
    .nav-item {
        display: inline-block;
        position: relative;
        padding: 0;
        .nav-link {
            padding: 2.2rem;
            font-size: 1.8rem;
        }
        &.active {
            .nav-link {
                color: $color-primary;
                background: $color-white;
                font-weight: $font-weight-bold;
                .icon {
                    background-position-y: -40px;
                }
            }
        }
    }
    .nav-link {
        font-family: $font-family-titles;
        font-weight: 500;
        color: $color-secondary-dark;
        @include media-breakpoint-down(md) {
            display: block;
            padding: $nav-link-padding 0;
            border-top: 1px solid $border-default-color;
        }
        .badge {
            float: left;
            margin: -1px 5px 0 0;
        }
        .icon {
            margin-top: -2px;
            margin-right: 4px;
            background-position-y: -140px;
        }
        &:hover {
            color: $color-primary;
            .icon {
                background-position-y: -40px;
            }
        }
    }
}

//
// Tabs
//

.nav-tabs {
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

    .nav-item {
        margin-bottom: -$nav-tabs-border-width;
        padding: 0;
        &:first-child .nav-link {
            border-radius: $nav-tabs-border-radius 0 0 0;
        }

        &:last-child  .nav-link {
            border-radius: 0 $nav-tabs-border-radius 0 0;
        }
    }

    .nav-link {
        padding-top: 12px;
        padding-bottom: 12px;
        font-family: $font-family-titles;
        font-size: 1.6rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        color: $color-secondary;
        background: $color-white;
        border: $nav-tabs-border-width solid transparent;
        border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
        border-left-width: 0;

        @include hover-focus {
            border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
        }

        &:not(:first-child) {
            border-left: none;
        }
        &.disabled {
            color: $nav-disabled-link-color;
            background-color: transparent;
            border-color: transparent;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        color: $nav-tabs-active-link-hover-color;
        background-color: $nav-tabs-active-link-hover-bg;
        border-color: $nav-tabs-active-link-hover-border-color $nav-tabs-active-link-hover-border-color $nav-tabs-active-link-hover-bg;
    }

    .dropdown-menu {
        // Make dropdown border overlap tab border
        margin-top: -$nav-tabs-border-width;
        // Remove the top rounded corners here since there is a hard edge above the menu
        @include border-top-radius(0);
    }
}

.nav-tabs .nav-item:first-child .nav-link {
    border-left-width: $nav-tabs-border-width;
}

.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 50rem;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 200;
    background: $color-white;
    transform: translateX(100%);
    transition: all .3s;
    &.open {
        transform: translateX(0);
    }
}

.mobile-menu-header {
    height: 8.5rem;
    padding-top: .8rem;
    .header-nav {
        float: left;
        .nav-items {
            margin: 0;
        }
        .nav-item {
            margin-left: 0;
            margin-right: .3rem;
        }
    }
}




.mobile-menu-body {
    padding-bottom: 2.4rem;
    .nav-link {
        display: block;
        padding: 1.2rem 0;
        font-size: 1.8rem;
        color: $color-gray-700;
        &:hover, &:active {
            color: $color-purple-dark;
        }
        &.active {
            color: $color-purple;
        }
        &.has-icon, &.has-badge {
            position: relative;
            padding-right: 3rem;
            .icon {
                position: absolute;
                top: 50%;
                right: .4rem;
                margin-top: 4px;
                transform: translateY(-50%);
            }
            .badge {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
        }
        &.has-sub {
            .icon {
                transition: all .24s;
            }
            &.opened .icon {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
    }
    .navbar-nav {
        .nav-item {
            padding: 0 2.4rem;
        }
        .level-1 > .nav-link {
            font-size: 2.2rem;
            padding-top: 1rem;
            padding-bottom: .6rem;
            color: $color-violet;
        }
        .navbar-sub-nav {
            margin: 0;
            padding: 0;
            list-style: none;
            .nav-item {
                padding: 0;
                .nav-link {
                    border-top: 1px solid $color-gray-300;
                }
                &:first-child .nav-link {
                    border-top: none;
                }
            }
        }
    }
}

.mobile-account-info {
    background: $color-gray-200;
    margin-bottom: 2.6rem;
    padding: 1rem 0;

    .sub-nav {
        padding-bottom: 1rem;
        .nav-link {
            padding-top: .2rem;
            padding-bottom: .2rem;
            font-size: 1.6rem;
            font-weight: $font-weight-base;
        }
    }
}

.nav-overlay {
    opacity: 0;
    background: $color-purple;
    transition: opacity .24s;
    &.open {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 180;
        overflow-x: hidden;
        overflow-y: auto;
        opacity: .65;
    }
}

.tab-content {
    > .tab-pane {
        display: none;
        border-color: $color-gray-400;
        border-radius: 0 0 $border-radius $border-radius;
    }
    > .active {
        display: block;
        background: $color-gray-200;
    }
}

