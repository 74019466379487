/*
Breadcrumb

Indicate the current page’s location within a navigational hierarchy.

Markup: docs/components/breadcrumb.hbs

Style guide: Components.breadcrumb
*/
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $breadcrumb-margin-bottom;
    padding: $breadcrumb-padding-y $breadcrumb-padding-x;
    border-bottom: 1px solid $color-gray-300;
    font-size: $breadcrumb-font-size;
    list-style: none;
    background-color: $breadcrumb-bg;
    @include border-radius($border-radius);
    @include clearfix;
    .icon {
        margin-top: -.3rem;
    }
}

.breadcrumb-item {
    display: inline-block;
    font-weight: $font-weight-bold;
    line-height: 2.4rem;
    @include hover-focus {
        color: $color-violet;
        text-decoration: underline;
        &.first {
            text-decoration: none;
            .icon {
                color: $color-violet-dark;
            }
        }
    }
    &.active {
        font-weight: $font-weight-medium;
        color: $breadcrumb-active-color;
        @include hover-focus {
            color: $breadcrumb-active-color;
        }
    }
}

// The separator between breadcrumbs (by default, a forward-slash: "/")
.breadcrumb-divider::before {
    display: inline-block;
    padding-right: $breadcrumb-item-padding;
    padding-left: $breadcrumb-item-padding;
}
