/*
Card

A card is a flexible and extensible content container. It includes options for headers and footers, a wide variety of content, contextual background colors, and powerful display options.

Markup: docs/components/card.hbs

Style guide: Components.card
*/

.card {
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: $card-bg;
    border: $card-border-width solid $card-border-color;
    @include border-radius($card-border-radius);
    &.card-default {
        border: none;
        background: $color-gray-100;
        .card-footer {
            border: none;
            background: $color-gray-300;
            color: $color-gray-700;
        }
        .card-header {
            border: none;
            margin: 0;
            padding: .8rem $card-spacer-x;
            background: $color-gray-300;
            color: $color-gray-700;
        }
    }
    &.is-highlighted {
        color: $color-lightest;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/$color-violet+0,7db9e8+100 */
        background: $color-violet; /* Old browsers */
        background: -moz-linear-gradient(-45deg,  $color-violet 0%, $color-red-light 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg,  $color-violet 0%,$color-red-light 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg,  $color-violet 0%,$color-red-light 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color-violet}', endColorstr='#{$color-red-light}',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
}

.card-category {
    border-radius: $border-radius-lg;
}

.card-block {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    padding: $card-spacer-x;
}

.card-title {
    margin-bottom: $card-spacer-y;
    font-size: $card-header-font-size;
    font-weight: 500;
}

.card-default-title {
    color: inherit;
}

.card-subtitle {
    margin-top: -($card-spacer-y / 2);
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-image {
    position: relative;
}

.card-link {
    @include hover {
        text-decoration: none;
    }

    + .card-link {
        margin-left: $card-spacer-x;
    }
}

.card {
    > .list-group:first-child {
        .list-group-item:first-child {
            @include border-top-radius($card-border-radius);
        }
    }

    > .list-group:last-child {
        .list-group-item:last-child {
            @include border-bottom-radius($card-border-radius);
        }
    }
}

//
// Optional textual caps
//

.card-header {
    margin-bottom: 0; // Removes the default margin-bottom of <hN>
    padding: .8rem $card-spacer-x;
    background-color: $card-cap-bg;
    border-bottom: 1px solid $card-border-color;
    border-radius: $card-border-radius $card-border-radius 0 0;

    &.header-dark {
        background-color: #DFDCD8;
        .btn-link {
            color: $color-gray;
            text-transform: none;
        }
    }
    .card-title {
        margin-bottom: 0;
        font-size: $card-header-font-size;
        font-weight: $font-weight-bold;
        color: $color-gray-800;
    }
    /*
    &:first-child {
        @include border-radius($card-border-radius-inner $card-border-radius-inner 0 0);
    }
    */
    &.has-icon {
        position: relative;
        padding-right: 52px;
        .card-header-icon {
            position: absolute;
            top: 16px;
            right: 17px;
        }
    }
    &.edit-step {
        .btn {
            position: absolute;
            top: 12px;
            right: 17px;
        }
        .icon {
            position: static;
        }
    }
}

.card-body {
    padding: $card-spacer-y $card-spacer-x;
    border-radius: 0 0 $card-border-radius $card-border-radius;
    &.body-only {
        border-radius: $card-border-radius $card-border-radius;
    }
}

.card-footer {
    padding: $card-spacer-y $card-spacer-x;
    background-color: $color-lightest;
    border-top: 1px solid $card-border-color;

    &:last-child {
        @include border-radius(0 0 $card-border-radius-inner $card-border-radius-inner);
    }
}

.js-card-clickable {
    cursor: pointer;
    a {
        position: relative;
        z-index: 10;
    }
    .icon-violet, a {
        transition: color .24s;
    }
    &:hover {
        .icon-violet,
        a {
            color: $color-violet-dark;
        }
    }
}
//
// Header navs
//

.card-header-tabs {
    margin-bottom: -$card-spacer-y;
    margin-left: -($card-spacer-x / 2);
    margin-right: -($card-spacer-x / 2);
    border-bottom: 0;
}

.card-header-pills {
    margin-left: -($card-spacer-x / 2);
    margin-right: -($card-spacer-x / 2);
}


//
// Card spacing variations
//

.card-spacing-sm {
    @include card-spacing-variant($card-spacing-sm-x, $card-spacing-sm-y);
}

.card-spacing-md {
    @include card-spacing-variant($card-spacing-md-x, $card-spacing-md-y);
}

//
// Background variations
// $text, $background, $border
//

.card-primary {
    @include card-variant($color-primary-dark, $color-primary-light, $color-primary);
}
.card-success {
    @include card-variant($color-success-dark, $color-success-light, $color-success);
}
.card-info {
    @include card-variant($color-info-dark, $color-info-light, $color-info);
}
.card-warning {
    @include card-variant($color-warning, $color-warning, $color-warning);
}
.card-danger {
    @include card-variant($color-danger-dark, $color-danger-light, $color-danger);
}
.card-default {
    @include card-variant($color-default, $color-default, $color-default);
    color: $color-gray-900;
}

// Remove all backgrounds
.card-outline-primary {
    @include card-outline-variant($color-primary-dark, $color-primary-light, $color-primary);
}
.card-outline-secondary {
    @include card-outline-variant($color-secondary-dark, $color-secondary-light, $color-secondary);
}
.card-outline-info {
    @include card-outline-variant($color-info-dark, $color-info-light, $color-info);
}
.card-outline-success {
    @include card-outline-variant($color-success-dark, $color-success-light, $color-success);
}
.card-outline-warning {
    @include card-outline-variant($color-warning-dark, $color-warning-light, $color-warning);
}
.card-outline-danger {
    @include card-outline-variant($color-danger-dark, $color-danger-light, $color-danger);
}
.card-outline-default {
    @include card-outline-variant($color-default-dark, $color-default-light, $color-default);
}


//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
    @include card-inverse;
}

//
// Blockquote
//

.card-blockquote {
    margin-bottom: 0;
    padding: 0;
    border-left: 0;
}

// Card image
.card-img {
    // margin: -1.325rem;
    @include border-radius($card-border-radius-inner);
}
.card-img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $card-img-overlay-padding;
}



// Card image caps
.card-img-top {
    @include border-top-radius($card-border-radius-inner);
}
.card-img-bottom {
    @include border-bottom-radius($card-border-radius-inner);
}


// Card deck

@include media-breakpoint-up(sm) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;

        .card {
            display: flex;
            flex: 1 0 0;
            flex-direction: column;

            // Selectively apply horizontal margins to cards to avoid doing the
            // negative margin dance like our grid. This differs from the grid
            // due to the use of margins as gutters instead of padding.
            &:not(:first-child) { margin-left: $card-deck-margin; }
            &:not(:last-child) { margin-right: $card-deck-margin; }
        }
    }
}


//
// Card groups
//

@include media-breakpoint-up(sm) {
    .card-group {
        display: flex;
        flex-flow: row wrap;

        .card {
            flex: 1 0 0;

            + .card {
                margin-left: 0;
                border-left: 0;
            }

            // Handle rounded corners
            @if $enable-rounded {
                &:first-child {
                    @include border-right-radius(0);

                    .card-img-top {
                        border-top-right-radius: 0;
                    }
                    .card-img-bottom {
                        border-bottom-right-radius: 0;
                    }
                }
                &:last-child {
                    @include border-left-radius(0);

                    .card-img-top {
                        border-top-left-radius: 0;
                    }
                    .card-img-bottom {
                        border-bottom-left-radius: 0;
                    }
                }

                &:not(:first-child):not(:last-child) {
                    border-radius: 0;

                    .card-img-top,
                    .card-img-bottom {
                        border-radius: 0;
                    }
                }
            }
        }
    }
}

//
// Rows with equal height cards
//

.card-row {
    @include media-breakpoint-up(md) {
        .card {
            height: 100%;
        }
    }
}

.card-horizontal {
    .card-header {
        padding: $card-spacing-sm-y 0;
    }

    .card-footer {
        padding: $card-spacing-sm-y 0;
    }
}

//
// Columns
//

@include media-breakpoint-up(sm) {
    .card-columns {
        column-count: $card-columns-count;
        column-gap: $card-columns-gap;

        .card {
            display: inline-block; // Don't let them vertically span multiple columns
            width: 100%; // Don't let their width change
            margin-bottom: $card-columns-margin;
        }
    }
}

// card link

.card-link-row {
    display: flex;
    margin-bottom: 3.6rem;
    .card-link {
        flex: 1 1 auto;
        flex-basis: 0;
        margin: 0;
        border-radius: 0;
        border-left-width: 1px;
        border-right-width: 1px;
        transition: all .24s;
        &:first-child {
            border-left-width: 2px;
            border-radius: $card-border-radius 0 0 $card-border-radius;
        }
        &:last-child {
            border-right-width: 2px;
            border-radius: 0 $card-border-radius $card-border-radius 0;
        }
    }
}

.card-link {
    border: 2px solid $color-violet;
    transition: all .2s;
    cursor: pointer;
    .card-body {
        padding: 1.3rem;
    }
    a, a:link, a:visited, a:hover, a:active {
        display: block;
        line-height: 1.2;
        text-decoration: none;
        color: $color-violet;
    }
    &:hover {
        border-color: $color-purple;
        a, a:link, a:visited, a:hover, a:active, .card-link-text {
            text-decoration: none;
            color: $color-purple;
        }
        .icon {
            background-position-y: -340px;
        }
    }
    &.active, &:active, &:active:focus, &.active:focus {
        background: $color-primary;
        border-color: $color-primary;
        outline: 0;

        a, a:link, a:visited, a:hover, a:active, .card-link-text {
            text-decoration: none;
            color: $color-lightest;
        }
        .icon {
            background-position-y: -340px;

        }
    }
    &:focus {
        border-color: $color-primary;
        outline: 0;
        a, a:link, a:visited, a:hover, a:active, .card-link-text {
            text-decoration: none;
            color: $color-primary;
        }
        .icon {
            background-position-y: -40px;
        }
    }
    .icon {
        margin-bottom: 8px;
    }
    a, .card-link-text {
        font-family: $font-family-titles;
        font-weight: 500;
        color: $color-violet;
    }
    &.radio-card {
        .card-body {
            padding: 0;
        }
        label {
            display: block;
            width: 100%;
            padding: 5rem 1.7rem 1.3rem 1.7rem;
            strong {
                display: block;
            }
            &:before {
                top: 20px;
                left: 50%;
                margin-left: -8px;
            }
        }
        &.current {
            border-color: $card-border-color;
        }
        &.standard {
            border-color: $color-secondary-dark;
        }
        &:focus, &:active {
            background: transparent;
        }
        &.active {
            background: $color-secondary;
            border-color: $color-secondary-dark;
            label {
                color: #FFF;
            }
        }
    }
    &.card-link-lg {
        @include media-breakpoint-down(sm) {
            padding-right: 4rem;
        }
        .icon {
            @include media-breakpoint-down(sm) {
                position: absolute;
                top: 1.4rem;
                right: 1.7rem;
            }
        }
        &:hover {
            .icon {
                background-position-y: -340px;
            }
            .icon-lg {
                background-position-y: -800px;
            }
        }
        &:active, &:focus {
            .icon {
                background-position-y: -340px;
            }
            .icon-lg {
                background-position-y: -800px;
            }
        }
    }
}

/*
	-----------------------------------------
	Card secondary
	-----------------------------------------
*/

.card-subitem {
    display: block;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.6rem;
    background: $color-lightest;
    border-radius: $border-radius;
    color: $color-gray-700;
    &.bordered {
        margin: 0;
        padding: 1rem 0;
        border-top: 1px solid $color-gray-300;
        border-radius: 0;
        &:first-child {
            border: none;
        }
    }
    &:first-child {
        margin-top: 0;
    }
    h3, .h3 {
        margin: 0;
        font-size: 2rem;
        color: $color-violet;
        font-weight: $font-weight-bold;
    }
    h4, .h4 {
        margin: 0;
        font-size: 1.6rem;
        color: $color-purple;
        font-weight: $font-weight-bold;
    }
    .card-subitem-header {
        margin: 0 -10px 10px;
        padding: 0 10px 10px;
        border-bottom: 1px solid $color-secondary-light;
    }
    &.is-link {
        font-family: $font-family-titles;
        font-weight: $btn-font-weight;
        text-decoration: none;
        @include transition(background, color, .32s);
        &:hover {
            text-decoration: none;
            color: $color-lightest;
            background: $color-primary;
            .icon {
                background-position-y: -340px;
            }
        }
    }
}

.total-price {
    font-size: 2rem;
    font-weight: $font-weight-bold;
}

.total-price-value {
    color: $color-violet;
}

.card-secondary {
    margin-bottom: 3rem;
    border: none;
    border-radius: $border-radius;
    .card-header {
        background: #5A2C71;
        border: none;
        .btn-link {
            color: $color-lightest;
            text-transform: none;
        }
    }
    .card-title {
        color: $color-lightest;
    }
    .card-body {
        background: $color-secondary-lightest;
        border: none;
    }
    .lead {
        color: $color-secondary-dark;
    }
    .total-price {
        color: $color-primary;
    }
}

/*
	-----------------------------------------
	tab cards
	-----------------------------------------
*/

.card.tab-pane {
    border-top: none;
    border-radius: 0 0 $border-radius $border-radius;
}

/*
	-----------------------------------------
	filter cards
	-----------------------------------------
*/

.card-filter {
    @include media-breakpoint-down(sm) {
        border: none;
    }
    .filter-item {
        font-family: $font-family-titles;
        font-size: 1.6rem;
        font-weight: 500;
        color: $color-gray;
        .icon {
            margin-right: 5px;
        }
        &.active {
            color: $color-primary-dark;
        }
    }
    .card-body {
        padding: 0 1.5rem;
    }
    .filter-row {
        position: relative;
        padding: 15px 0;
        border-top: 1px solid $border-default-color;
        color: $color-gray-900;
        transition: background .24s;
        @include media-breakpoint-down(sm) {
            border: 1px solid $card-border-color;
            margin-bottom: 2.4rem;
            @include border-radius(.6rem);
        }
        .filter-row-title {
            font-size: 2rem;
        }
        &:first-child {
            border-top: 0;
            @include media-breakpoint-down(sm) {
                border-top: 1px solid $border-default-color;
                &:after {
                    @include border-radius(.6rem .6rem 0 0);
                }
            }
        }
        &:hover {
            cursor: pointer;
            background: $color-gray-100;
            &:after {
                border-color: $color-secondary;
            }
        }
        &:last-child {
            @include border-radius(0 0 .7rem .7rem);
        }
    }
}

.card-ean { //because of logic explained 2 days before delivery, sorry
    margin-bottom: 3.6rem;
}
