/*
Close

Use a generic close icon for dismissing content like modals and alerts. Be sure to include text for screen readers, as we’ve done with aria-label.

Markup: docs/components/close.hbs

Style guide: Components.close
*/

.close {
    float: right;
    font-size: $close-font-size;
    font-weight: $close-font-weight;
    line-height: 1;
    text-shadow: $close-text-shadow;
    color: $close-color;
    opacity: .5;
    @include hover-focus {
        text-decoration: none;
        color: $close-color;
        cursor: pointer;
        opacity: .75;
    }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

button.close {
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    -webkit-appearance: none;
}
