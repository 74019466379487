@media print {
    .nav, .btn, .steps-navigation, .nav-lang, .footer-primary, .header-primary .header-title, .phase-slider, .tab-content, .nav-tabs, .hidden-print, iframe, .navbar, input, textarea, .breadcrumb, .login-cta, .header-primary, .comments-section::before, .comments-section::after, .dz-message {
        display: none !important;
    }

    .site-logo {
        display: block !important;
        width: 152px;
    }

    .card.card-secondary, .card {
        border: 1px solid $border-default-color;

    }

    .card-header .card-title {
        color: #333;
    }

    .dz-preview {
        padding-left: $input-padding-x;
        &::before {
            display: none;
        }
    }

    .card-subitems .card-subitem {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid #CCC;
        border-radius: 0;
    }

    .card-body.body-only .card-subitem {
        border: none;
    }

    .card, .card-secondary {
        border: $card-border-width solid $card-border-color !important;
        @include border-radius($card-border-radius);
        page-break-inside:avoid !important;
    }

    .card .card-header {
        border-bottom: 1px solid $card-border-color !important;
        border-radius: $border-radius $border-radius 0 0;
    }

    .col-md-6, .col-lg-6, .col-sm-6 {
        width: 50%;
    }

    .comments-pane {
        max-height: none;
        overflow: auto;
        .comment {
            page-break-inside: avoid !important;
            .comment-body {
                color: $color-gray-700;
            }
        }
    }

    h1, h2, h3, h4, h5, h6 {
        page-break-after: avoid;
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
        page-break-inside: avoid !important;
    }

    blockquote, table, pre {
        page-break-inside: avoid;
    }

}
