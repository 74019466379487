.phase-slider {
    border: none !important;
}

.phase-change {
    position: relative;
    padding: 41px 28px 41px;
    @include media-breakpoint-up(md) {
        padding: 41px 28px 80px;
    }
}

.phase-block {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 40px;
    font-family: $font-family-titles;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    z-index: 0;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //pointer-events: none;
    @include media-breakpoint-up(md) {
        top: 0 !important;
        width: 60px;
        height: 100px;
        padding: 0;

    }
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 1px;
        margin-left: -9px;
        content: '';
        background: $color-gray-500;
        @include media-breakpoint-up(md) {
            top: 43px;
            left: 50%;
            width: 1px;
            height: 18px;
            margin-left: 0;
        }
    }
    &.phase-step-01, &:last-child {
        &:before {
            display: none;
        }
    }
    .amp {
        position: absolute;
        top: 8px;
        left: 35%;
        color: #999;
        @media screen and (max-width: 400px) {
            left: 29%;
        }
        @include media-breakpoint-up(md) {
            left: 0;
            width: 100%;
        }
    }
    .kva {
        position: absolute;
        top: 8px;
        right: 34%;
        color: $color-secondary-dark;
        @media screen and (max-width: 400px) {
            right: 27%;
        }
        @include media-breakpoint-up(md) {
            top: auto;
            bottom: 8px;
            left: 0;
            right: auto;
            width: 100%;
        }
    }
    .phase-desc {
        display: block;
        position: absolute;
        top: 6px;
        left: 15px;
        font-weight: $font-weight-bold;
        font-size: 1.6rem;
        @media screen and (max-width: 400px) {
            top: 6px;
            font-size: 1.2rem;
        }
        @include media-breakpoint-up(md) {
            top: auto;
            bottom: -30px;
            left: 50%;
            width: 200%;
            text-align: center;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            transform: translateX(-50%);
        }
    }

    &.current {
        border: 2px solid $border-default-color;
        border-radius: $border-radius;
        color: $color-gray-700;
        &:before {
            @include media-breakpoint-up(md) {
                top: 40px;
            }
        }
    }
    &.standard {
        border: 2px solid $color-violet;
        border-radius: $border-radius;
        &:before {
            @include media-breakpoint-up(md) {
                top: 40px;
            }
        }
        .phase-desc {
            color: $color-violet;
        }
        &.current {
            .phase-desc {
                top: 4px;
                line-height: 1.2;
                @include media-breakpoint-up(md) {
                    top: auto;
                    bottom: -40px;
                }
            }

        }
    }
}

/* items again here for readability */

.phase-block {
    &.phase-step-01 {
        top: 415px;
    }
    &.phase-step-02 {
        top: 348px;
    }
    &.phase-step-03 {
        top: 281px;
    }
    &.phase-step-04 {
        top: 215px;
    }
    &.phase-step-05 {
        top: 149px;
    }
    &.phase-step-06 {
        top: 81px;
    }
    &.phase-step-07 {
        top: 16px;
    }
    /*
    @include media-breakpoint-up(md) {
        &.phase-step-01 {
            left: -.3%;
        }
        &.phase-step-02 {
            left: 15.2%;
        }
        &.phase-step-03 {
            left: 29.8%;
        }
        &.phase-step-04 {
            left: 44.3%;
        }
        &.phase-step-05 {
            left: 58.8%;
        }
        &.phase-step-06 {
            left: 73.7%;
        }
        &.phase-step-07 {
            left: 88.8%;
        }

    }*/
    @include media-breakpoint-up(md) {
        &.phase-step-01 {
            left: -.3%;
        }
        &.phase-step-02 {
            left: 15.2%;
        }
        &.phase-step-03 {
            left: 30.4%;
        }
        &.phase-step-04 {
            left: 45.7%;
        }
        &.phase-step-05 {
            left: 60.8%;
        }
        &.phase-step-06 {
            left: 75.7%;
        }
        &.phase-step-07 {
            left: 91%;
        }
    }
    @include media-breakpoint-up(lg) {
        &.phase-step-01 {
            left: -1%;
        }
        &.phase-step-02 {
            left: 14%;
        }
        &.phase-step-03 {
            left: 28.2%;
        }
        &.phase-step-04 {
            left: 43%;
        }
        &.phase-step-05 {
            left: 58%;
        }
        &.phase-step-06 {
            left: 72.6%;
        }
        &.phase-step-07 {
            left: 86.8%;
        }
    }
    @include media-breakpoint-up(xl) {
        &.phase-step-01 {
            left: -.3%;
        }
        &.phase-step-02 {
            left: 15.2%;
        }
        &.phase-step-03 {
            left: 30.4%;
        }
        &.phase-step-04 {
            left: 45.7%;
        }
        &.phase-step-05 {
            left: 61%;
        }
        &.phase-step-06 {
            left: 76.2%;
        }
        &.phase-step-07 {
            left: 91.6%;
        }
    }

}

.steps-6 .phase-block {
    &.phase-step-01 {
        top: 415px;
    }
    &.phase-step-02 {
        top: 334px;
    }
    &.phase-step-03 {
        top: 256px;
    }
    &.phase-step-04 {
        top: 177px;
    }
    &.phase-step-05 {
        top: 96px;
    }
    &.phase-step-06 {
        top: 16px;
    }
    /*
    @include media-breakpoint-up(md) {
        &.phase-step-01 {
            left: 0;
        }
        &.phase-step-02 {
            left: 17.7%;
        }
        &.phase-step-03 {
            left: 35.3%;
        }
        &.phase-step-04 {
            left: 53.1%;
        }
        &.phase-step-05 {
            left: 7.8%;
        }
        &.phase-step-06 {
            left: 88.5%;
        }

    }
    */
    @include media-breakpoint-up(md) {
        &.phase-step-01 {
            left: 0;
        }
        &.phase-step-02 {
            left: 18.2%;
        }
        &.phase-step-03 {
            left: 36.4%;
        }
        &.phase-step-04 {
            left: 54.7%;
        }
        &.phase-step-05 {
            left: 72.8%;
        }
        &.phase-step-06 {
            left: 90.9%;
        }
    }
    @include media-breakpoint-up(lg) {
        &.phase-step-01 {
            left: -.4%;
        }
        &.phase-step-02 {
            left: 16.8%;
        }
        &.phase-step-03 {
            left: 34.2%;
        }
        &.phase-step-04 {
            left: 52%;
        }
        &.phase-step-05 {
            left: 69.3%;
        }
        &.phase-step-06 {
            left: 86.7%;
        }
    }
    @include media-breakpoint-up(xl) {
        &.phase-step-01 {
            left: 0;
        }
        &.phase-step-02 {
            left: 18.2%;
        }
        &.phase-step-03 {
            left: 36.6%;
        }
        &.phase-step-04 {
            left: 54.8%;
        }
        &.phase-step-05 {
            left: 73.1%;
        }
        &.phase-step-06 {
            left: 91.6%;
        }
    }
}

.steps-4 .phase-block {
    &.phase-step-01 {
        top: 415px;
    }
    &.phase-step-02 {
        top: 283px;
    }
    &.phase-step-03 {
        top: 148px;
    }
    &.phase-step-04 {
        top: 16px;
    }

    /*
    @include media-breakpoint-up(md) {
        &.phase-step-01 {
            left: 0;
        }
        &.phase-step-02 {
            left: 17.7%;
        }
        &.phase-step-03 {
            left: 35.3%;
        }
        &.phase-step-04 {
            left: 53.1%;
        }
        &.phase-step-05 {
            left: 7.8%;
        }
        &.phase-step-06 {
            left: 88.5%;
        }

    }
    */
    @include media-breakpoint-up(md) {
        &.phase-step-01 {
            left: 0;
        }
        &.phase-step-02 {
            left: 30.3%;
        }
        &.phase-step-03 {
            left: 60.8%;
        }
        &.phase-step-04 {
            left: 91.2%;
        }
    }
    @include media-breakpoint-up(lg) {
        &.phase-step-01 {
            left: 0;
        }
        &.phase-step-02 {
            left: 29.8%;
        }
        &.phase-step-03 {
            left: 60%;
        }
        &.phase-step-04 {
            left: 89.9%;
        }
    }
    @include media-breakpoint-up(xl) {
        &.phase-step-01 {
            left: 0;
        }
        &.phase-step-02 {
            left: 30.5%;
        }
        &.phase-step-03 {
            left: 61%;
        }
        &.phase-step-04 {
            left: 91.6%;
        }
    }
}

.price-increase-warning {
    margin-top: 1.5rem;
}

.color-secondary-dark {
    color: $color-secondary-dark;
}

/* slider overwrites */

.ui-slider-horizontal {
    //margin-left: 25px !important;
}
