/*
Spacing

Assign responsive-friendly margin or padding values to an element or a subset of its sides with shorthand classes. Includes support for individual properties, all properties, and vertical and horizontal properties. Classes are built from a default Sass map ranging from .25rem to 3rem.

Markup: docs/utilities/spacing.hbs

Style guide: Utilities.spacing
*/

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (margin: m, padding: p) {
            @each $size, $length in $spacers {

                .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
                .#{$abbrev}t#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-top: $length !important;
                }
                .#{$abbrev}r#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }
                .#{$abbrev}b#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-bottom: $length !important;
                }
                .#{$abbrev}l#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }
            }
        }

        // Some special margin utils
        .m#{$infix}-auto { margin: auto !important; }
        .mt#{$infix}-auto,
        .my#{$infix}-auto {
            margin-top: auto !important;
        }
        .mr#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-right: auto !important;
        }
        .mb#{$infix}-auto,
        .my#{$infix}-auto {
            margin-bottom: auto !important;
        }
        .ml#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-left: auto !important;
        }
    }
}

//exception use for requests page
.pt-05 {
    padding-top: .3rem;
}
