// Since the salesforce uses a div id="root", we have to fix it on this id. this should have been on the body:

// sass-lint:disable-all
#rootAppContainer {
    // Fixed footer
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h4.color-primary {
    color: $color-primary; //unfortunatly there was only one exception...
}

// sass-lint:enable-all
@include media-breakpoint-up(xl) {
    .col-request-info {  // a border that is out of the grid. dirty but had to come there
        position: relative;
        &:after {
            position: absolute;
            top: -30px;
            bottom: 0;
            right: -50px;
            width: 1px;
            content: '';
            background: $border-default-color;
            z-index: 10;
        }
    }
}
