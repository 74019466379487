.badge {
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x;
    font-size: $badge-font-size;
    font-weight: $badge-font-weight;
    line-height: 1;
    text-align: center;
    color: $badge-color;
    white-space: nowrap;
    hyphens: auto;
    vertical-align: baseline;
    @include border-radius($badge-border-radius);
    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
    &.is-highlighted {
        color: $color-lightest;
        background: $color-gray-700;
    }
    &.has-hover {
        cursor: pointer;
        transition: color .24s, background-color .24s;
        @include hover-focus {
            color: $color-lightest;
            background: $color-gray-900;
            text-decoration: none;
        }
    }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $name, $value in $badge-colors {
    @include badge-variant($name, $value);
}


@each $name, $value in $message-colors {
    @include badge-variant($name, $value);
}
