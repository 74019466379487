/*
Input group

Easily extend form controls by adding text, buttons, or button groups on either side of textual `<input>`s.

Markup: docs/core/input-group.hbs

Style guide: Core.input
*/

//
// Base styles
//

.input-group {
    display: flex;
    position: relative;
    width: 100%;

    .form-control {
        // Ensure that the input is always above the *appended* addon button for
        // proper border colors.
        position: relative;
        flex: 1 1 auto;
        // Add width 1% and flex-basis auto to ensure that button will not wrap out
        // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
        width: 1%;
        margin-bottom: 0;
        z-index: 2;

        // Bring the "active" form control to the front
        @include hover-focus-active {
            z-index: 3;
        }
    }
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
    // Vertically centers the content of the addons within the input group
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-child):not(:last-child) {
        @include border-radius(0);
    }
}

.input-group-addon,
.input-group-btn {
    white-space: nowrap;
    vertical-align: middle; // Match the inputs
}

.input-group-addon .bootstrap-select .btn.dropdown-toggle {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}


// Sizing options
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

// sass-lint:disable placeholder-in-extend
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
    @extend .form-control-lg;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
    @extend .form-control-sm;
}
// sass-lint:enable placeholder-in-extend


//
// Text input groups
//

.input-group-addon {
    margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
    padding: $input-padding-y $input-padding-x;
    font-size: $font-size-base; // Match inputs
    font-weight: $font-weight-normal;
    line-height: $input-line-height;
    text-align: center;
    color: $input-group-addon-color;
    background-color: $input-group-addon-bg;
    border: 1px solid $input-group-addon-border-color;
    @include border-radius($input-border-radius);

    // Sizing
    &.form-control-sm {
        padding: $input-padding-y-sm $input-padding-x-sm;
        font-size: $font-size-sm;
        @include border-radius($input-border-radius-sm);
    }
    &.form-control-lg {
        padding: $input-padding-y-lg $input-padding-x-lg;
        font-size: $font-size-lg;
        @include border-radius($input-border-radius-lg);
    }

    // Nuke default margins from checkboxes and radios to vertically center within.
    input[type="radio"],
    input[type="checkbox"] {
        margin-top: 0;
    }
    &.danger {
        color: $color-danger;
        background: $alert-danger-bg;
        border-color: $color-danger;
    }
    &.success {
        color: $color-success-dark;
        background: $color-success;
        border-color: $color-success-dark;
    }
}


//
// Reset rounded corners
//

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
    @include border-right-radius(0);
}
.input-group-addon:not(:last-child) {
    border-right: 0;
}
.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
    @include border-left-radius(0);
}
.form-control + .input-group-addon:not(:first-child) {
    border-left: 0;
}

//
// Button input groups
//

.input-group-btn {
    position: relative;
    // Jankily prevent input button groups from wrapping with `white-space` and
    // `font-size` in combination with `inline-block` on buttons.
    font-size: 0;
    white-space: nowrap;
    z-index: 20;
    // Negative margin for spacing, position for bringing hovered/focused/actived
    // element above the siblings.
    > .btn {
        position: relative;
        // Vertically stretch the button and center its content
        flex: 1;
        margin-bottom: 0;
        + .btn {
            margin-left: (-$input-btn-border-width);
        }

        // Bring the "active" button to the front
        @include hover-focus-active {
            z-index: 3;
        }
    }

    // Negative margin to only have a single, shared border between the two
    &:not(:last-child) {
        > .btn,
        > .btn-group {
            margin-right: 0;
        }
    }
    &:not(:first-child) {
        > .btn,
        > .btn-group {
            margin-left: (-$input-btn-border-width);
            z-index: 2;
            // Because specificity
            @include hover-focus-active {
                z-index: 3;
            }
        }
    }
}
