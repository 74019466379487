.panel {
    padding: 0;
    border-radius: $panel-border-radius;
    .panel-header {
        padding: $panel-padding-y $panel-padding-x;
    }
    .panel-body {
        padding: $panel-padding-y $panel-padding-x;
    }
}

.panel-default {
    @include panel-variant($panel-default-color, $panel-default-bg);
}

.panel-collapse {
    margin-top: 1.6rem;
    border: 1px solid $card-border-color;
    .panel-body {
        padding: 2.4rem $panel-padding-x;
    }
    .panel-footer {
        background: $color-gray-200;
        padding: 2.4rem $panel-padding-x;
        p:last-child {
            margin: 0;
        }
    }
    &.active {
        border-color: $color-primary;
        .panel-header {
            color: $color-primary;
            border-bottom: 1px solid $color-primary;
            .radio-label {
                color: $color-primary;
            }
        }
    }
    .panel-header {
        &.radio {
            padding: 0;
            .radio-label {
                display: block;
                margin: 0;
                padding: $panel-padding-y $panel-padding-x $panel-padding-x 4.5rem;
                &:before {
                    left: $panel-padding-x;
                    margin-top: $panel-padding-y;
                }
            }
        }
    }
}
