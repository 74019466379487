.hero {
    position: relative;
    margin-bottom: 0;
    background-color: $hero-bg;
}

.hero-image {
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-white;
        content: '';
        z-index: 2;
        @include media-breakpoint-up(lg) {
            background-color: transparent;
            @include gradient-x($start-color: rgba(255,255,255,1), $end-color: rgba(255,255,255,0), $start-percent: 45%, $end-percent: 67%);
        }
    }
    .hero-content {
        padding: ($grid-gutter-width-md * 2) 0;
        @include media-breakpoint-up(lg) {
            padding: ($hero-padding / 2) 0;
        }
        @include media-breakpoint-up(xl) {
            padding: $hero-padding 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .hero-text {
        margin-bottom: 20px;
    }
}

.hero-content {
    position: relative;
    z-index: 5;
}

.hero-negative {
    .hero-text {
        color: $color-lightest;
    }

    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        background-color: rgba(0,0,0,.5);
    }
}


.hero-hr {
    border-top-color: darken($hero-bg, 10%);
}

.hero-fluid {
    padding-left: 0;
    padding-right: 0;
    @include border-radius(0);
}
