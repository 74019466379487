.icon {
    display: inline-block;
    position: relative;
    top: -.2rem;
    width: $icon-size-default;
    height: $icon-size-default;
    line-height: 1;
    vertical-align: middle;
}

.icon-md {
    height: $icon-size-lg-small;
    width: $icon-size-lg-small;
}


.icon-lg {
    height: $icon-size-lg-small;
    width: $icon-size-lg-small;

    @include media-breakpoint-up(md) {
        height: $icon-size-lg;
        width: $icon-size-lg;
    }
}

.icon-white {
    color: $color-white;
}

.icon-purple {
    color: $color-purple;
}

.icon-violet {
    color: $color-violet;
}

.icon-danger {
    color: $color-danger-dark;
}

.icon-gray-600 {
    color: $color-gray-600;
}

.icon-gray-800 {
    color: $color-gray-800;
}


/*
.icon {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-top: -4px;
    line-height: 1;
    vertical-align: middle;
    @include sprite();
}
*/
//
// Icon Sizes
//
/*
.icon-md { @include icon-size($icon-size-md); }
.icon-lg { @include icon-size($icon-size-lg); }
.icon-xl { @include icon-size($icon-size-xl); }

.icon-lg-xl {
    @include media-breakpoint-up(lg) { @include icon-size($icon-size-xl); }
}


// small icons

.icon-plus {
    background-position: -40px -40px;
    &.secondary { background-position: -40px -140px; }
    &.neutral { background-position: -40px -240px; }
    &.lightest { background-position: -40px -340px; }
    &.danger { background-position: -40px -440px; }
    &.success { background-position: -40px -540px; }
}

.icon-close {
    background-position: -140px -40px;
    &.secondary { background-position: -140px -140px; }
    &.neutral { background-position: -140px -240px; }
    &.lightest { background-position: -140px -340px; }
    &.danger { background-position: -140px -440px; }
    &.success { background-position: -140px -540px; }
}

.icon-check {
    background-position: -240px -40px;
    &.secondary { background-position: -240px -140px; }
    &.neutral { background-position: -240px -240px; }
    &.lightest { background-position: -240px -340px; }
    &.danger { background-position: -240px -440px; }
    &.success { background-position: -240px -540px; }
}

.icon-down {
    background-position: -340px -40px;
    &.secondary { background-position: -340px -140px; }
    &.neutral { background-position: -340px -240px; }
    &.lightest { background-position: -340px -340px; }
    &.danger { background-position: -340px -440px; }
    &.success { background-position: -340px -540px; }
}

.icon-user {
    background-position: -440px -40px;
    &.secondary { background-position: -440px -140px; }
    &.neutral { background-position: -440px -240px; }
    &.lightest { background-position: -440px -340px; }
    &.danger { background-position: -440px -440px; }
    &.success { background-position: -440px -540px; }
}

.icon-meter {
    background-position: -540px -40px;
    &.secondary { background-position: -540px -140px; }
    &.neutral { background-position: -540px -240px; }
    &.lightest { background-position: -540px -340px; }
    &.danger { background-position: -540px -440px; }
    &.success { background-position: -540px -540px; }
}

.icon-stats {
    background-position: -640px -40px;
    &.secondary { background-position: -640px -140px; }
    &.neutral { background-position: -640px -240px; }
    &.lightest { background-position: -640px -340px; }
    &.danger { background-position: -640px -440px; }
    &.success { background-position: -640px -540px; }
}

.icon-list {
    background-position: -740px -40px;
    &.secondary { background-position: -740px -140px; }
    &.neutral { background-position: -740px -240px; }
    &.lightest { background-position: -740px -340px; }
    &.danger { background-position: -740px -440px; }
    &.success { background-position: -740px -540px; }
}

.icon-clock {
    background-position: -840px -40px;
    &.secondary { background-position: -840px -140px; }
    &.neutral { background-position: -840px -240px; }
    &.lightest { background-position: -840px -340px; }
    &.danger { background-position: -840px -440px; }
    &.success { background-position: -840px -540px; }
}

.icon-bell {
    background-position: -940px -40px;
    &.secondary { background-position: -940px -140px; }
    &.neutral { background-position: -940px -240px; }
    &.lightest { background-position: -940px -340px; }
    &.danger { background-position: -940px -440px; }
    &.success { background-position: -940px -540px; }
}

.icon-calendar {
    background-position: -1040px -40px;
    &.secondary { background-position: -1040px -140px; }
    &.neutral { background-position: -1040px -240px; }
    &.lightest { background-position: -1040px -340px; }
    &.danger { background-position: -1040px -440px; }
    &.success { background-position: -1040px -540px; }
}

.icon-clip {
    background-position: -1140px -40px;
    &.secondary { background-position: -1140px -140px; }
    &.neutral { background-position: -1140px -240px; }
    &.lightest { background-position: -1140px -340px; }
    &.danger { background-position: -1140px -440px; }
    &.success { background-position: -1140px -540px; }
}

.icon-warning {
    background-position: -1240px -40px;
    &.secondary { background-position: -1240px -140px; }
    &.neutral { background-position: -1240px -240px; }
    &.lightest { background-position: -1240px -340px; }
    &.danger { background-position: -1240px -440px; }
    &.success { background-position: -1240px -540px; }
}

.icon-info {
    background-position: -1340px -40px;
    &.secondary { background-position: -1340px -140px; }
    &.neutral { background-position: -1340px -240px; }
    &.lightest { background-position: -1340px -340px; }
    &.danger { background-position: -1340px -440px; }
    &.success { background-position: -1340px -540px; }
}

.icon-invertor {
    background-position: -1440px -40px;
    &.secondary { background-position: -1440px -140px; }
    &.neutral { background-position: -1440px -240px; }
    &.lightest { background-position: -1440px -340px; }
    &.danger { background-position: -1440px -440px; }
    &.success { background-position: -1440px -540px; }
}

.icon-plug {
    background-position: -1540px -40px;
    &.secondary { background-position: -1540px -140px; }
    &.neutral { background-position: -1540px -240px; }
    &.lightest { background-position: -1540px -340px; }
    &.danger { background-position: -1540px -440px; }
    &.success { background-position: -1540px -540px; }
}

.icon-lock {
    background-position: -1640px -40px;
    &.secondary { background-position: -1640px -140px; }
    &.neutral { background-position: -1640px -240px; }
    &.lightest { background-position: -1640px -340px; }
    &.danger { background-position: -1640px -440px; }
    &.success { background-position: -1640px -540px; }
}

.icon-exit {
    background-position: -1740px -40px;
    &.secondary { background-position: -1740px -140px; }
    &.neutral { background-position: -1740px -240px; }
    &.lightest { background-position: -1740px -340px; }
    &.danger { background-position: -1740px -440px; }
    &.success { background-position: -1740px -540px; }
}

.icon-trash {
    background-position: -1840px -40px;
    &.secondary { background-position: -1840px -140px; }
    &.neutral { background-position: -1840px -240px; }
    &.lightest { background-position: -1840px -340px; }
    &.danger { background-position: -1840px -440px; }
    &.success { background-position: -1840px -540px; }
}

.icon-euro {
    background-position: -1940px -40px;
    &.secondary { background-position: -1940px -140px; }
    &.neutral { background-position: -1940px -240px; }
    &.lightest { background-position: -1940px -340px; }
    &.danger { background-position: -1940px -440px; }
    &.success { background-position: -1940px -540px; }
}

.icon-lookingglass {
    background-position: -2040px -40px;
    &.secondary { background-position: -2040px -140px; }
    &.neutral { background-position: -2040px -240px; }
    &.lightest { background-position: -2040px -340px; }
    &.danger { background-position: -2040px -440px; }
    &.success { background-position: -2040px -540px; }
}

.icon-right {
    background-position: -2140px -40px;
    &.secondary { background-position: -2140px -140px; }
    &.neutral { background-position: -2140px -240px; }
    &.lightest { background-position: -2140px -340px; }
    &.danger { background-position: -2140px -440px; }
    &.success { background-position: -2140px -540px; }
}

.icon-left {
    background-position: -2140px -40px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    &.secondary { background-position: -2140px -140px; }
    &.neutral { background-position: -2140px -240px; }
    &.lightest { background-position: -2140px -340px; }
    &.danger { background-position: -2140px -440px; }
    &.success { background-position: -2140px -540px; }
}

.icon-check-circle {
    background-position: -2240px -40px;
    &.secondary { background-position: -2240px -140px; }
    &.neutral { background-position: -2240px -240px; }
    &.lightest { background-position: -2240px -340px; }
    &.danger { background-position: -2240px -440px; }
    &.success { background-position: -2240px -540px; }
}

.icon-wrench {
    background-position: -2340px -40px;
    &.secondary { background-position: -2340px -140px; }
    &.neutral { background-position: -2340px -240px; }
    &.lightest { background-position: -2340px -340px; }
    &.danger { background-position: -2340px -440px; }
    &.success { background-position: -2340px -540px; }
}

.icon-remove {
    background-position: -2440px -40px;
    &.secondary { background-position: -2440px -140px; }
    &.neutral { background-position: -2440px -240px; }
    &.lightest { background-position: -2440px -340px; }
    &.danger { background-position: -2440px -440px; }
    &.success { background-position: -2440px -540px; }
}

.icon-print {
    background-position: -2540px -40px;
    &.secondary { background-position: -2540px -140px; }
    &.neutral { background-position: -2540px -240px; }
    &.lightest { background-position: -2540px -340px; }
    &.danger { background-position: -2540px -440px; }
    &.success { background-position: -2540px -540px; }
}

.icon-fuse {
    background-position: -2640px -40px;
    &.secondary { background-position: -2640px -140px; }
    &.neutral { background-position: -2640px -240px; }
    &.lightest { background-position: -2640px -340px; }
    &.danger { background-position: -2640px -440px; }
    &.success { background-position: -2640px -540px; }
}

.icon-mail {
    background-position: -2740px -40px;
    &.secondary { background-position: -2740px -140px; }
    &.neutral { background-position: -2740px -240px; }
    &.lightest { background-position: -2740px -340px; }
    &.danger { background-position: -2740px -440px; }
    &.success { background-position: -2740px -540px; }
}

.icon-home {
    background-position: -2840px -40px;
    &.secondary { background-position: -2840px -140px; }
    &.neutral { background-position: -2840px -240px; }
    &.lightest { background-position: -2840px -340px; }
    &.danger { background-position: -2840px -440px; }
    &.success { background-position: -2840px -540px; }
}

.icon-book {
    background-position: -2940px -40px;
    &.secondary { background-position: -2940px -140px; }
    &.neutral { background-position: -2940px -240px; }
    &.lightest { background-position: -2940px -340px; }
    &.danger { background-position: -2940px -440px; }
    &.success { background-position: -2940px -540px; }
}

.icon-comment {
    background-position: -3040px -40px;
    &.secondary { background-position: -3040px -140px; }
    &.neutral { background-position: -3040px -240px; }
    &.lightest { background-position: -3040px -340px; }
    &.danger { background-position: -3040px -440px; }
    &.success { background-position: -3040px -540px; }
}

.icon-document {
    background-position: -3140px -40px;
    &.secondary { background-position: -3140px -140px; }
    &.neutral { background-position: -3140px -240px; }
    &.lightest { background-position: -3140px -340px; }
    &.danger { background-position: -3140px -440px; }
    &.success { background-position: -3140px -540px; }
}

.icon-gas {
    background-position: -3240px -40px;
    &.secondary { background-position: -3240px -140px; }
    &.neutral { background-position: -3240px -240px; }
    &.lightest { background-position: -3240px -340px; }
    &.danger { background-position: -3240px -440px; }
    &.success { background-position: -3240px -540px; }
}

.icon-up {
    background-position: -3340px -40px;
    &.secondary { background-position: -3340px -140px; }
    &.neutral { background-position: -3340px -240px; }
    &.lightest { background-position: -3340px -340px; }
    &.danger { background-position: -3340px -440px; }
    &.success { background-position: -3340px -540px; }
}


.icon-location {
    background-position: -3440px -40px;
    &.secondary { background-position: -3440px -140px; }
    &.neutral { background-position: -3440px -240px; }
    &.lightest { background-position: -3440px -340px; }
    &.danger { background-position: -3440px -440px; }
    &.success { background-position: -3440px -540px; }
}


.icon-electricity-lg {
    background-position: 0 -600px;
    &.secondary { background-position: 0 -700px; }
    &.lightest { background-position: 0 -800px; }
}

.icon-gas-lg {
    background-position: -100px -600px;
    &.secondary { background-position: -100px -700px; }
    &.lightest { background-position: -100px -800px; }
}

.icon-book-lg {
    background-position: -200px -600px;
    &.secondary { background-position: -200px -700px; }
    &.lightest { background-position: -200px -800px; }
}

.icon-certificate-lg {
    background-position: -300px -600px;
    &.secondary { background-position: -300px -700px; }
    &.lightest { background-position: -300px -800px; }
}

.icon-education-lg {
    background-position: -400px -600px;
    &.secondary { background-position: -400px -700px; }
    &.lightest { background-position: -400px -800px; }
}

.icon-contact-lg {
    background-position: -500px -600px;
    &.secondary { background-position: -500px -700px; }
    &.lightest { background-position: -500px -800px; }
}

.icon-document-lg {
    background-position: -600px -600px;
    &.secondary { background-position: -600px -700px; }
    &.lightest { background-position: -600px -800px; }
}

.icon-euro-lg {
    background-position: -700px -600px;
    &.secondary { background-position: -700px -700px; }
    &.lightest { background-position: -700px -800px; }
}

.icon-history-lg {
    background-position: -800px -600px;
    &.secondary { background-position: -800px -700px; }
    &.lightest { background-position: -800px -800px; }
}

.icon-home-lg {
    background-position: -900px -600px;
    &.secondary { background-position: -900px -700px; }
    &.lightest { background-position: -900px -800px; }
}

.icon-info-lg {
    background-position: -1000px -600px;
    &.secondary { background-position: -1000px -700px; }
    &.lightest { background-position: -1000px -800px; }
}

.icon-meter-lg {
    background-position: -1100px -600px;
    &.secondary { background-position: -1100px -700px; }
    &.lightest { background-position: -1100px -800px; }
}

.icon-search-lg {
    background-position: -1200px -600px;
    &.secondary { background-position: -1200px -700px; }
    &.lightest { background-position: -1200px -800px; }
}

.icon-works-lg {
    background-position: -1300px -600px;
    &.secondary { background-position: -1300px -700px; }
    &.lightest { background-position: -1300px -800px; }
}

.icon-question-lg {
    background-position: -1400px -600px;
    &.secondary { background-position: -1400px -700px; }
    &.lightest { background-position: -1400px -800px; }
}

.icon-mail-lg {
    background-position: -1500px -600px;
    &.secondary { background-position: -1500px -700px; }
    &.lightest { background-position: -1500px -800px; }
}

.icon-calendar-lg {
    background-position: -1600px -600px;
    &.secondary { background-position: -1600px -700px; }
    &.lightest { background-position: -1600px -800px; }
}

.icon-comment-lg {
    background-position: 0 -910px;
}

//
.nav-item:hover,
.nav-item.active {
    .icon-account { background-position: -440px -141px; }
    .icon-signout {background-position-y: -141px;}
}
*/
