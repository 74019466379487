/*
 Header
 */

// Primary header
//

.header-primary {
    height: 8.5rem;
    box-sizing: border-box;
    @include gradient-x($start-color: rgba(90,44,113,1), $end-color: rgba(211,101,181,1), $start-percent: 0%, $end-percent: 100%);
    @include media-breakpoint-up(lg) {
        height: auto;
        padding: 1.3rem 0 0;
    }
    .header-title {
        margin: 13px 0 0 0;
        font-family: $font-family-titles;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 4.375rem;
        text-transform: uppercase;
        color: #5A2C70;
        a {
            display: inline-block;
            padding-left: 15px;
            text-decoration: none;
            color: #5A2C70;
            @include media-breakpoint-down(xs) {
                padding: 0;
            }
            &:hover {
                text-decoration: none;
            }
        }
        @include media-breakpoint-down(xs) {
            margin: 0;
            padding: 0;
        }
    }


    .container {
        @include media-breakpoint-up(lg) {
            padding-bottom: 1.1rem;
        }
    }

    @include clearfix();
}


.site-branding {
    position: relative;
    top: .8rem;
    float: left;
    margin-left: -2rem;
    @include clearfix();
    transform: scale(.8);
    @include media-breakpoint-up(lg) {
        top: 0;
        transform: scale(1);
        margin-left: 0;
    }
}

.mobile-menu-toggle {
    float: right;
    margin-top: 1.7rem;
    font-size: 1.4rem;
    color: $color-white;
    font-weight: $font-weight-bold;
    text-align: center;
    @include media-breakpoint-up(lg) {
        display: none;
    }
    .icon {
        width: 3.6rem;
        height: 3.6rem;
        padding: .4rem .5rem;
        border: 2px solid $color-white;
        border-radius: .4rem;
        transition: border-color .24s;
    }
    &:hover {
        color: $color-purple-dark;
        .icon {
            border-color: $color-purple-dark;
        }
    }
    &.menu-close {
        margin-top: 1.8rem;
        .icon {
            background: $color-violet;
            border-color: $color-violet;
        }

    }
}
