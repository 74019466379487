.cookie-warning {
    position: fixed;
    bottom: 20px;
    right: 20px;
    max-width: 390px;
    padding: 20px;
    background: #5A2C71;
    border-radius: 5px;
    z-index: 1000;
    @media screen and (max-width: 680px) {
        width: 100%;
        max-width: none;
        border-radius: 0;
        right: 0;
        bottom: 0;
    }
    .cookie-warning-info {
        color: #FFF;
        padding-bottom: 20px;
    }

    .btn-cookie {
        display: inline-block;
        margin-right: 10px;
        padding: 8px;
        background: #FFF;
        font-size: 13px;
        color: #5A2C71;
        font-family: $font-family-titles;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 4px;
        text-decoration: none;
        &:hover {
            background: #EFEFEF;
        }
        &:focus {
            overflow: unset;
        }
    }

    .btn-link {
        font-size: 13px;
        color: #FFF;
        text-decoration: underline;
        &:hover {
            color: #EFEFEF;
        }
    }

}


