/*
Type

Simple and easily customized typography for headings, body text, lists, and more. For even more control, check out the textual utility classes.

Markup: docs/core/type.hbs

Style guide: Core.type
*/

//
// Headings
//

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
}

h1, .h1 {
    font-size: $font-size-h1;
    line-height: $line-height-h1;
    color: $color-h1;
    margin-bottom: $margin-bottom-h1;
    @include media-breakpoint-up(lg) {
        font-size: $font-size-h1-lg;
        line-height: $line-height-h1-lg;
    }
}

h2, .h2 {
    font-size: $font-size-h2;
    line-height: $line-height-h2;
    color: $color-h2;
    margin-bottom: $margin-bottom-h2;
    @include media-breakpoint-up(lg) {
        font-size: $font-size-h2-lg;
        line-height: $line-height-h2-lg;
    }
}

h3, .h3 {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
    color: $color-h3;
    margin-bottom: $margin-bottom-h3;
    @include media-breakpoint-up(lg) {
        font-size: $font-size-h3-lg;
        line-height: $line-height-h3-lg;
    }
}

h4, .h4 {
    font-size: $font-size-h4;
    line-height: $line-height-h4;
    color: $color-h4;
    margin-bottom: $margin-bottom-h4;
}

h5, .h5 {
    font-size: $font-size-h5;
    line-height: $line-height-h5;
    color: $color-h5;
    margin-bottom: $margin-bottom-h5;
    @include media-breakpoint-up(lg) {
        font-size: $font-size-h5-lg;
        line-height: $line-height-h5-lg;
    }
}

h6, .h6 {
    font-size: $font-size-h6;
    line-height: $line-height-h6;
    color: $color-h6;
    margin-bottom: $margin-bottom-h6;
}

.label {
    font-size: $font-size-base;
    font-weight: 600;
}


.lead {
    margin-bottom: $lead-margin-bottom;
    font-size: $lead-font-size;
    line-height: $lead-line-height;
    font-weight: $lead-font-weight;
    color: $lead-color;
    @include media-breakpoint-up(lg) {
        font-size: $lead-font-size-lg;
        line-height: $lead-line-height-lg;
    }
}

// Display headings
.d-h1 {
    font-size: $display-h1-size;
    font-weight: $display-h1-weight;
    line-height: $display-line-height;
}
.d-d2 {
    font-size: $display-h2-size;
    font-weight: $display-h2-weight;
    line-height: $display-line-height;
}
.d-h3 {
    font-size: $display-h3-size;
    font-weight: $display-h3-weight;
    line-height: $display-line-height;
}
.d-h4 {
    font-size: $display-h4-size;
    font-weight: $display-h4-weight;
    line-height: $display-line-height;
}

.text-negative {
    color: $color-lightest;
    h1, h2, h3, h4, .lead {
        color: $color-lightest;
    }
}

.text-lighter, .text-lighter a {
    color: $color-primary-lightest;
}


//
// Horizontal rules
//

hr {
    margin-top: $spacer-y;
    margin-bottom: $spacer-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
    font-size: $small-font-size;
    font-weight: $font-weight-normal;
    line-height: 1.2;
}

mark,
.mark {
    padding: $mark-padding;
    background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
    @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
    @include list-unstyled;
}
.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: $list-inline-padding;
    }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

// Blockquotes
blockquote {
    margin-bottom: $spacer;
    padding: 0;
    font-family: $font-family-titles;
    font-size: $font-size-h2;
    @include responsive-type($blockquote-font-size);
    font-style: $blockquote-font-style;
    color: $blockquote-color;
    border-left: none;
}

.blockquote-footer {
    display: block;
    font-size: 80%; // back to default font-size
    color: $blockquote-small-color;

    &::before {
        content: "\2014 \00A0"; // em dash, nbsp
    }
}

// Opposite alignment of blockquote
.blockquote-reverse {
    padding-left: 0;
    padding-right: $spacer;
    text-align: right;
    border-left: 0;
    border-right: $blockquote-border-width solid $blockquote-border-color;
}

.blockquote-reverse .blockquote-footer {
    &::before {
        content: "";
    }
    &::after {
        content: "\00A0 \2014"; // nbsp, em dash
    }
}
