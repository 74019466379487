/*
Text

The following utilities can be used to add additional styles to texts.

Markup: docs/utilities/text.hbs

Style guide: Utilities.text
*/

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .text#{$infix}-left   { text-align: left !important; }
        .text#{$infix}-right  { text-align: right !important; }
        .text#{$infix}-center { text-align: center !important; }
    }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-normal { font-weight: $font-weight-normal; }
.font-bold   { font-weight: $font-weight-bold; }
.font-italic { font-style: italic; }

// Font sizing

.text-xs { font-size: $font-size-xs; }
.text-sm { font-size: $font-size-sm; }
.text-md { font-size: $font-size-md; }
.text-lg { font-size: $font-size-lg; }

.text-base-lg {
    @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
    }
}

.no-break {
    white-space: nowrap;
}

// Contextual colors

.text-white {
    color: $color-lightest !important;
}

.color-light {
    color: $color-gray !important;
}


@include text-emphasis-variant('.text-muted', $color-text-muted);
@include text-emphasis-variant('.text-primary', $color-primary);
@include text-emphasis-variant('.text-success', $color-success);
@include text-emphasis-variant('.text-info', $color-info);
@include text-emphasis-variant('.text-warning', $color-warning);
@include text-emphasis-variant('.text-danger', $color-danger);
@include text-emphasis-variant('.text-secondary', $color-secondary);

// Font color

@include text-emphasis-variant('.text-gray-dark', $color-gray-dark);

// Misc

.text-hide {
    @include text-hide();
}

.text-underline {
    text-decoration: underline;
}
