/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutters: $grid-gutter-widths) {
    position: relative;
    margin-left: auto;
    margin-right: auto;

    @each $breakpoint in map-keys($gutters) {
        @include media-breakpoint-up($breakpoint) {
            $gutter: map-get($gutters, $breakpoint);
            padding-left:  ($gutter / 2);
            padding-right: ($gutter / 2);
        }
    }
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            width: $container-max-width;
            max-width: 100%;
        }
    }
}

@mixin make-gutters($gutters: $grid-gutter-widths) {
    @each $breakpoint in map-keys($gutters) {
        @include media-breakpoint-up($breakpoint) {
            $gutter: map-get($gutters, $breakpoint);
            padding-left:  ($gutter / 2);
            padding-right: ($gutter / 2);
        }
    }
}

@mixin make-row($gutters: $grid-gutter-widths) {
    display: flex;
    flex-wrap: wrap;

    @each $breakpoint in map-keys($gutters) {
        @include media-breakpoint-up($breakpoint) {
            $gutter: map-get($gutters, $breakpoint);
            margin-left:  ($gutter / -2);
            margin-right: ($gutter / -2);
        }
    }
}

@mixin make-col-ready($gutters: $grid-gutter-widths) {
    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing

    @each $breakpoint in map-keys($gutters) {
        @include media-breakpoint-up($breakpoint) {
            $gutter: map-get($gutters, $breakpoint);
            padding-left:  ($gutter / 2);
            padding-right: ($gutter / 2);
        }
    }
}

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage($size / $columns);
    // width: percentage($size / $columns);
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
    margin-left: percentage($size / $columns);
}

@mixin make-col-push($size, $columns: $grid-columns) {
    left: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-pull($size, $columns: $grid-columns) {
    right: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-modifier($type, $size, $columns) {
    // Work around the lack of dynamic mixin @include support (https://github.com/sass/sass/issues/626)
    @if $type == push {
        @include make-col-push($size, $columns);
    } @else if $type == pull {
        @include make-col-pull($size, $columns);
    } @else if $type == offset {
        @include make-col-offset($size, $columns);
    }
}