//
// CHECKBOXES
//

.checkbox {

    // normal checkbox-input
    .check-input {
        position: absolute;
        left: -99999px;
    }

    label, .checkbox-label {
        position: relative;
        z-index: 1;
        a {
            position: relative;
            text-decoration: underline;
            z-index: 10;
        }
    }
    &.disabled {
        cursor: no-drop;
        opacity: .6;
        small {
            color: #555;
        }
    }

    // styled checkbox-input
    label:before {
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 0;
        width: 17px;
        height: 17px;
        margin: 0 .8rem 0 0;
        content: '';
        background-color: $color-lightest;
        border: 1px solid $hr-border-color;
        @include border-radius(3px);
    }

    input[type="checkbox"] + label {
        position: relative;
        clear: none;
        margin: 0 0 .5rem 0;
        padding-left: 25px;
        cursor: pointer;
        z-index: 1;
    }

    .check-label.checked {
        clear: none;
        margin: 0 0 .5rem 0;
        cursor: pointer;

        &:before {
            @include sprite();
            background-color: $color-purple;
            background-position: -243px -342px;
            border-color: $color-purple;
        }
    }

    input[type="checkbox"]:disabled:checked + label {
        color: $input-color-disabled;
        cursor: not-allowed;
        pointer-events: none;

        &:before {
            @include sprite();
            background-color: $input-color-disabled !important;
            background-position: -243px -342px;
            border-color: $input-color-disabled;
            cursor: not-allowed;
            &:hover {
                cursor: not-allowed;
            }
        }
        &:hover {
            cursor: not-allowed;
        }
    }

    input[type="checkbox"]:disabled + label {
        color: $input-color-disabled;
        cursor: not-allowed;
        pointer-events: none;

        &:before {
            background-color: $color-lightest;
            background-image: none;
            border-color: $input-color-disabled;
            cursor: not-allowed;
            &:hover {
                cursor: not-allowed;
            }
        }
        &:hover {
            cursor: not-allowed;
        }
    }

    .has-error &, &.has-error & {

        input[type="checkbox"] + label {
            color: $color-danger;
            &:before { border-color: $color-danger; }
        }

    }
}

label.check-label:focus, label.check-label.focussed {
    outline: 0;
    &:before {
        -webkit-box-shadow: $btn-focus-box-shadow;
        -moz-box-shadow: $btn-focus-box-shadow;
        box-shadow: $btn-focus-box-shadow;
    }
}
