.page-body {
    flex: 1 1 auto;
}

.page-main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-intro {
    padding: 3.6rem 0 4.8rem;
    border-bottom: 1px solid $border-default-color;
    @include media-breakpoint-up(lg) {
        padding: 4.2rem 0 9.6rem;
    }
}

.page-header {
    padding: 2rem 0 2.5rem;
    border-bottom: 1px solid $border-default-color;
}

.page-title {
    margin-bottom: 3rem;
    padding-bottom: 1.3rem;
    border-bottom: 1px solid $hr-border-color;
    h2, h3, h4 {
        display: inline-block;
        margin: 0;
        &.history {
            color: $color-secondary;
        }
    }
    .icon {
        margin: -2px .3rem 0;
    }
}

.page-content {
    flex: 1 0 auto;
    // Nested page header in the content area
    .page-header {
        padding-top: 0;
        border-bottom: none;
    }
}

