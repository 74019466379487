@charset "UTF-8";

/*!
 * Sibelga Express Theme v0.0.3
 *
 * @author      Brecht De Ruyte (brecht.deruyte@marlon.be)
 * @copyright   Marlon bvba <http://www.marlon.be>
 *
 */

// Core variables and mixins
@import 'theme/fonts';
@import 'theme/variables';
@import 'mixins/mixins';

// Reset and dependencies
@import 'core/reboot';

// Core CSS
@import 'core/type';
@import 'core/icons';
@import 'core/images';
@import 'core/grid';
//@import 'core/tables';
@import 'core/forms';
@import 'core/buttons';
@import 'core/button-group';
@import 'core/dropdown';
@import 'core/input-group';
@import 'core/list-group';
//@import 'core/media';

// Components

@import 'components/footer';
@import 'components/header';
//@import 'components/arrow';
//@import 'components/accordion';
@import 'components/alert';
@import 'components/badge';
@import 'components/cta';
@import 'components/cookiewarning';
//@import 'components/box';
@import 'components/breadcrumb';
@import 'components/card';
@import 'components/dropzone';
//@import 'components/carousel';
@import 'components/comments';
@import 'components/close';
@import 'components/collapse';
//@import 'components/cookies';
//@import 'components/datagrid';
//@import 'components/item';
//@import 'components/list';
@import 'components/modal';
@import 'components/nav';
@import 'components/navbar';
@import 'components/pagination';
//@import 'components/placeholder';
//@import 'components/popover';
//@import 'components/progress';
//@import 'components/rte';
//@import 'components/search';
//@import 'components/tabs';
@import 'components/tooltip';
@import 'components/loading';
@import 'components/bootstrap-select';

@import 'components/checkboxes';
@import 'components/radios';
@import 'components/phaseslider';
@import 'theme/_shame.scss';

//vendor
@import 'vendor/jqueryui';
@import 'vendor/calendar';


// Webshop
//@import 'components/basket';
//@import 'components/compare';
//@import 'components/checkout';
@import 'components/page';
@import 'components/panel';
//@import 'components/product';
//@import 'components/gallery';
//@import 'components/facets';
@import 'components/hero';
//@import 'components/slider';
//@import 'components/price';
//@import 'components/stepper';
@import 'components/steps';
@import 'components/wizard';
//@import 'components/widget';

// Utilitiy classes
@import 'core/utilities';
@import 'core/print';
