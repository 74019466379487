/*
Button group

Group a series of buttons together on a single line with the button group. Add on optional JavaScript radio and checkbox style behavior with the buttons plugin.

Markup: docs/core/button-group.hbs

Style guide: Core.button-group
*/

// scss-lint:disable QualifyingElement

// Make the div behave like a button
.btn-group,
.btn-group-vertical {
    display: inline-flex;
    position: relative;
    vertical-align: middle; // match .btn alignment given font-size hack above

    > .btn {
        position: relative;
        flex: 0 1 auto;

        // Bring the hover, focused, and "active" buttons to the fron to overlay
        // the borders properly
        @include hover {
            z-index: 2;
        }
        &:focus,
        &:active,
        &.active {
            z-index: 2;
        }
    }

    // Prevent double borders when buttons are next to each other
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
        margin-left: -$input-btn-border-width;
    }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
    display: flex;
    justify-content: flex-start;

    .input-group {
        width: auto;
    }
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
}

// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group > .btn:first-child {
    margin-left: 0;

    &:not(:last-child):not(.dropdown-toggle) {
        @include border-right-radius(0);
    }
}
// Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu immediately after it
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
    @include border-left-radius(0);
}

// Custom edits for including btn-groups within btn-groups (useful for including dropdown buttons within a btn-group)
.btn-group > .btn-group {
    float: left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
}
.btn-group > .btn-group:first-child:not(:last-child) {
    > .btn:last-child,
    > .dropdown-toggle {
        @include border-right-radius(0);
    }
}
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    @include border-left-radius(0);
}

// On active and open, don't show outline
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
    outline: 0;
}


// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.
// sass-lint:disable placeholder-in-extend
.btn-group-sm > .btn { @extend .btn-sm; }
.btn-group-lg > .btn { @extend .btn-lg; }
// sass-lint:enable placeholder-in-extend

//
// Split button dropdowns
//

.btn + .dropdown-toggle-split {
    padding-left: $btn-padding-x * .75;
    padding-right: $btn-padding-x * .75;

    &::after {
        margin-left: 0;
    }
}

.btn-sm + .dropdown-toggle-split {
    padding-left: $btn-padding-x-sm * .75;
    padding-right: $btn-padding-x-sm * .75;
}

.btn-lg + .dropdown-toggle-split {
    padding-left: $btn-padding-x-lg * .75;
    padding-right: $btn-padding-x-lg * .75;
}


// The clickable button for toggling the menu
// Remove the gradient and set the same inset shadow as the :active state
.btn-group.open .dropdown-toggle {
    @include box-shadow($btn-active-box-shadow);

    // Show no shadow for `.btn-link` since it has no other button styles.
    &.btn-link {
        @include box-shadow(none);
    }
}


//
// Vertical button groups
//

.btn-group-vertical {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .btn,
    .btn-group {
        width: 100%;
    }

    > .btn + .btn,
    > .btn + .btn-group,
    > .btn-group + .btn,
    > .btn-group + .btn-group {
        margin-top: -$input-btn-border-width;
        margin-left: 0;
    }
}

.btn-group-vertical > .btn {
    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
    &:first-child:not(:last-child) {
        @include border-bottom-radius(0);
    }
    &:last-child:not(:first-child) {
        @include border-top-radius(0);
    }
}
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
}
.btn-group-vertical > .btn-group:first-child:not(:last-child) {
    > .btn:last-child,
    > .dropdown-toggle {
        @include border-bottom-radius(0);
    }
}
.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
    @include border-top-radius(0);
}


// Checkbox and radio options
//
// In order to support the browser's form validation feedback, powered by the
// `required` attribute, we have to "hide" the inputs via `clip`. We cannot use
// `display: none;` or `visibility: hidden;` as that also hides the popover.
// Simply visually hiding the inputs via `opacity` would leave them clickable in
// certain cases which is prevented by using `clip` and `pointer-events`.
// This way, we ensure a DOM element is visible to position the popover from.
//
// See https://github.com/twbs/bootstrap/pull/12794 and
// https://github.com/twbs/bootstrap/pull/14559 for more information.

[data-toggle="buttons"] {
    > .btn,
    > .btn-group > .btn {
        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            clip: rect(0,0,0,0);
            pointer-events: none;
        }
    }
}

// Toggle switchers
.btn-group-toggle {
    display: block;
}