/*
Alert

Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.

Markup: docs/components/alert.hbs

Style guide: Components.alert
*/

.alert {
    position: relative;
    margin-bottom: $alert-margin-bottom;
    padding: 2.4rem;
    border: $alert-border-width solid transparent;
    @include border-radius($alert-border-radius);
    .icon {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    p:last-child {
        margin: 0;
    }
    &.btr-0 {
        @include border-radius(0 0 $alert-border-radius $alert-border-radius);
    }
}

// Headings for larger alerts
.alert-heading {
    // Specified to prevent conflicts of changing $headings-color
    color: inherit;
}

// Provide class for links that match alerts
.alert-link {
    font-weight: $alert-link-font-weight;
}

.alert-sm {
    padding: ($alert-padding-y / 2) ($alert-padding-x / 2);
}

.alert-title {
    display: block;
    margin-bottom: 10px;
    color: inherit;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
    // Adjust close link position
    .close {
        position: relative;
        top: -$alert-padding-y;
        right: -$alert-padding-x;
        padding: $alert-padding-y $alert-padding-x;
        color: inherit;
    }
}


// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.
// $color, $background, $border

.alert-default {
    @include alert-variant($alert-default-text, $alert-default-bg, $alert-default-border);
}

.alert-secondary {
    padding: 15px;
    @include alert-variant($color-secondary-dark, $color-secondary-lightest, $color-secondary-lightest);
}

.alert-success {
    @include alert-variant($alert-success-text, $alert-success-bg, $alert-success-border);
}
.alert-info {
    @include alert-variant($alert-info-text, $alert-info-bg, $alert-info-border);
    .alert-title {
        color: $color-secondary-dark;
    }
}
.alert-warning {
    @include alert-variant($alert-warning-text, $alert-warning-bg, $alert-warning-border);
    a, a:link, a:visited {
        color: $color-orange-dark;
    }
}
.alert-danger {
    @include alert-variant($alert-danger-text, $alert-danger-bg, $alert-danger-border);
    a, a:link, a:visited {
        color: $color-danger;
    }
    a:hover, a:active {
        color: darken($color-danger, 5%);
    }
}

/* login cta */

.login-cta {
    padding: 1rem 0;
    border: none;
    border-bottom: 1px solid $color-orange;
    .icon {
        position: absolute;
        top: 1.1rem;
        right: 1.5rem;
        @include media-breakpoint-down(sm) {
            right: 2.4rem;
        }
    }
}
