.pagination {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.page-link {
    display: block;
    position: relative;
    margin-left: -$pagination-border-width;
    padding: $pagination-padding-y $pagination-padding-x;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    line-height: $pagination-line-height;
    color: $pagination-color;
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;
    transition: background-color .24s;
    @include hover-focus {
        text-decoration: none;
        color: $pagination-hover-color;
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border;
    }
    &.has-icon {
        padding: .5rem;
    }
}

.page-item {
    .icon {
        top: 0;
        color: $color-violet;
    }
    &:first-child {
        .page-link {
            margin-left: 0;
            @include border-left-radius($border-radius);
        }
    }
    &:last-child {
        .page-link {
            @include border-right-radius($border-radius);
        }
    }

    &.active .page-link {
        color: $pagination-active-color;
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border;
        z-index: 2;
    }

    &.disabled .page-link {
        color: $pagination-disabled-color;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border;
        cursor: $cursor-disabled; // While `pointer-events: none` removes the cursor in modern browsers, we provide a disabled cursor as a fallback.
        pointer-events: none;
    }
}
