.color-violet {
    color: $color-violet;
}

.color-purple {
    color: $color-purple;
}

.color-gray-700 {
    color: $color-gray-700;
}

.color-gray-900 {
    color: $color-gray-900;
}
