// Alerts
@mixin alert-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    hr {
        border-top-color: darken($border, 5%);
    }
    .alert-link {
        color: darken($color, 10%);
    }
}