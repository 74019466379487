// This is a third party scss, disable linting for all

// sass-lint:disable-all

$color-red-error: rgb(185, 74, 72) !default;
$color-grey-arrow: rgba(204, 204, 204, .2) !default;

$width-default: 100% !default; // 3 960px-grid columns

$zindex-select-dropdown: 1060 !default; // must be higher than a modal background (1050)

//** Placeholder text color
$input-color-placeholder: #999 !default;

// Mixins
@mixin cursor-disabled() {
    cursor: not-allowed;
}

@mixin box-sizing($fmt) {
    -webkit-box-sizing: $fmt;
    -moz-box-sizing: $fmt;
    box-sizing: $fmt;
}

@mixin box-shadow($fmt) {
    -webkit-box-shadow: $fmt;
    box-shadow: $fmt;
}

@function fade($color, $amnt) {
    @if $amnt > 1 {
        $amnt: $amnt / 100; // convert to percentage if int
    }
    @return rgba($color, $amnt);
}

// Rules
select.bs-select-hidden,
select.selectpicker {
    display: none !important;
}

.bootstrap-select {
    width: 220px;

    // The selectpicker button
    > .dropdown-toggle {
        width: 100%;
        padding-right: 25px;
        z-index: 1;

        &.bs-placeholder,
        &.bs-placeholder:hover,
        &.bs-placeholder:focus,
        &.bs-placeholder:active {
            color: $input-color-placeholder;
        }
    }

    > select {
        position: absolute !important;
        bottom: 0;
        left: 50%;
        display: block !important;
        width: .5px !important;
        height: 100% !important;
        padding: 0 !important;
        opacity: 0 !important;
        border: none;

        &.mobile-device {
            top: 0;
            left: 0;
            display: block !important;
            width: 100% !important;
            z-index: 2;
        }
    }

    // Error display
    .has-error & .dropdown-toggle,
    .error & .dropdown-toggle {
        border-color: $color-red-error;
    }

    &.fit-width {
        width: auto !important;
    }

    &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
        width: $width-default;
    }

    .dropdown-toggle:focus {
        outline: 0;
        -webkit-box-shadow: $btn-focus-box-shadow;
        -moz-box-shadow: $btn-focus-box-shadow;
        box-shadow: $btn-focus-box-shadow;
    }
}

.bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
    position: relative;

    &:not([class*="col-"]) {
        width: 100%;
    }

    &.input-group-btn {
        z-index: auto;

        &:not(:first-child):not(:last-child) {
            > .btn {
                border-radius: 0;
            }
        }
    }
}

// The selectpicker components
.bootstrap-select.btn-group {
    &:not(.input-group-btn),
    &[class*="col-"] {
        float: none;
        display: inline-block;
        margin-left: 0;
    }

    // Forces the pull to the right, if necessary
    &,
    &[class*="col-"],
    .row &[class*="col-"] {
        &.dropdown-menu-right {
            float: right;
        }
    }

    .form-inline &,
    .form-horizontal &,
    .form-group & {
        margin-bottom: 0;
    }

    .form-group-lg &.form-control,
    .form-group-sm &.form-control {
        padding: 0;

        .dropdown-toggle {
            height: 100%;
            font-size: inherit;
            line-height: inherit;
            border-radius: inherit;
        }
    }

    // Set the width of the live search (and any other form control within an inline form)
    // see https://github.com/silviomoreto/bootstrap-select/issues/685
    .form-inline & .form-control {
        width: 100%;
    }

    &.disabled,
    > .disabled {
        @include cursor-disabled();

        &:focus {
            outline: none !important;
        }
    }

    &.bs-container {
        position: absolute;
        height: 0 !important;
        padding: 0 !important;

        .dropdown-menu {
            z-index: $zindex-select-dropdown;
        }
    }

    // The selectpicker button
    .dropdown-toggle {
        .filter-option {
            display: inline-block;
            overflow: hidden;
            width: 100%;
            text-align: left;
        }
        .bs-caret {
            position: absolute;
            top: 50%;
            right: 12px;
            margin-top: -3px;
            vertical-align: middle;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath fill='%23A3117B' fill-rule='evenodd' d='M15.2928932,9.29289322 C15.6834175,8.90236893 16.3165825,8.90236893 16.7071068,9.29289322 C17.0976311,9.68341751 17.0976311,10.3165825 16.7071068,10.7071068 L12.7071068,14.7071068 C12.3165825,15.0976311 11.6834175,15.0976311 11.2928932,14.7071068 L7.29289322,10.7071068 C6.90236893,10.3165825 6.90236893,9.68341751 7.29289322,9.29289322 C7.68341751,8.90236893 8.31658249,8.90236893 8.70710678,9.29289322 L12,12.5857864 L15.2928932,9.29289322 Z' transform='translate(-7 -9)'/%3E%3C/svg%3E");
            width: 10px;
            height: 6px;
        }
        .caret {

        }
    }

    &[class*="col-"] .dropdown-toggle {
        width: 100%;
    }

    // The selectpicker dropdown
    .dropdown-menu {
        min-width: 100%;
        @include box-sizing(border-box);
        max-width: 100%;
        &.inner {
            position: static;
            float: none;
            border: 0;
            padding: 0;
            margin: 0;
            border-radius: 0;
            box-shadow: none;
        }

        li {
            position: relative;

            &.active small {
                color: #FFF;
            }

            &.disabled a {
                @include cursor-disabled();
            }

            a {
                cursor: pointer;
                user-select: none;
                font-weight: $font-weight-normal;
                &:focus {
                    outline: 0;
                    /*
                    -webkit-box-shadow: $btn-focus-box-shadow;
                    -moz-box-shadow: $btn-focus-box-shadow;
                    box-shadow: $btn-focus-box-shadow;
                    */
                }
                &.opt {
                    position: relative;
                    padding-left: 2.25em;
                }

                span.check-mark {
                    display: none;
                }

                span.text {
                    display: inline-block;
                }
            }

            small {
                padding-left: .5em;
            }
        }

        .notify {
            position: absolute;
            bottom: 5px;
            width: 96%;
            margin: 0 2%;
            min-height: 26px;
            padding: 3px 5px;
            background: rgb(245, 245, 245);
            border: 1px solid rgb(227, 227, 227);
            @include box-shadow(inset 0 1px 1px fade(rgb(0, 0, 0), 5));
            pointer-events: none;
            opacity: .9;
            @include box-sizing(border-box);
        }
    }

    .no-results {
        padding: 3px;
        background: #F5F5F5;
        margin: 0 5px;
        white-space: nowrap;
    }

    &.fit-width .dropdown-toggle {
        .filter-option {
            position: static;
        }

        .caret {
            position: static;
            top: auto;
            margin-top: -1px;
        }
    }

    &.show-tick .dropdown-menu li {
        &.selected a span.check-mark {
            position: absolute;
            display: inline-block;
            right: 15px;
            margin-top: 5px;
        }

        a span.text {
            margin-right: 34px;
        }
    }
}

.bootstrap-select.show-menu-arrow {
    &.open > .dropdown-toggle {
        z-index: ($zindex-select-dropdown + 1);
    }

    .dropdown-toggle {
        &:before {
            display: none;
        }

        &:after {
            display: none;
        }
    }

    &.dropup .dropdown-toggle {
        &:before {
            bottom: auto;
            top: -3px;
            border-top: 7px solid $color-grey-arrow;
            border-bottom: 0;
        }

        &:after {
            bottom: auto;
            top: -3px;
            border-top: 6px solid white;
            border-bottom: 0;
        }
    }

    &.pull-right .dropdown-toggle {
        &:before {
            right: 12px;
            left: auto;
        }

        &:after {
            right: 13px;
            left: auto;
        }
    }

    &.open > .dropdown-toggle {
        &:before,
        &:after {
            display: block;
        }
    }
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
    padding: 4px 8px;
}

.bs-actionsbox {
    width: 100%;
    @include box-sizing(border-box);

    & .btn-group button {
        width: 50%;
    }
}

.bs-donebutton {
    float: left;
    width: 100%;
    @include box-sizing(border-box);

    & .btn-group button {
        width: 100%;
    }
}


.bs-searchbox {
    position: relative;
    &:after {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 60 (88103) - https://sketch.com --%3E%3Ctitle%3Eicon / glyph / search%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='icon-/-glyph-/-search' transform='translate(-2.000000, -2.000000)' fill='%23968D88'%3E%3Cg id='icon'%3E%3Cpath d='M18.0318994,16.6176858 L21.7071068,20.2928932 C22.0976311,20.6834175 22.0976311,21.3165825 21.7071068,21.7071068 C21.3165825,22.0976311 20.6834175,22.0976311 20.2928932,21.7071068 L16.6176858,18.0318994 C15.078015,19.2635271 13.1250137,20 11,20 C6.02943725,20 2,15.9705627 2,11 C2,6.02943725 6.02943725,2 11,2 C15.9705627,2 20,6.02943725 20,11 C20,13.1250137 19.2635271,15.078015 18.0318994,16.6176858 L18.0318994,16.6176858 Z M16.0413859,15.8563911 C17.2541765,14.5976949 18,12.8859457 18,11 C18,7.13400675 14.8659932,4 11,4 C7.13400675,4 4,7.13400675 4,11 C4,14.8659932 7.13400675,18 11,18 C12.8859457,18 14.5976949,17.2541765 15.8563911,16.0413859 C15.8827083,16.0071587 15.9115424,15.9742441 15.9428932,15.9428932 C15.9742441,15.9115424 16.0071587,15.8827083 16.0413859,15.8563911 L16.0413859,15.8563911 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        content: '';
        position: absolute;
        right: 30px;
        top: 18px;
        width: 20px;
        height: 20px;

    }
    & + .bs-actionsbox {
        padding: 0 15px 4px;
    }

    & .form-control {
        margin-bottom: 0;
        width: 100%;
        float: none;
        padding-right: 40px;
    }
}

.bootstrap-select {
    .bs-searchbox {
        padding: 4px 15px;
    }
    .btn.dropdown-toggle {
        color: $input-color;
        padding-top: 13px;
        height: 49px;
        background: $color-white;
        border: 1px solid $input-border-color;
        text-transform: none;
        font-weight: $font-weight-base;
        &::after {
            display: none;
        }
    }
    .open .dropdown-menu {
        display: block;
        max-height: 240px !important;
    }
    ul.dropdown-menu.open {
        display: block;
        min-height: 0 !important;
        max-height: 240px !important;
    }
    li {
        width: 100%;
        min-height: 40px;
        a, a:link, a:visited {
            display: block;
            color: $input-color;
            width: 100%;
            padding: 10px 15px;
        }
        a:hover, a:active {
            color: $color-primary;
            background: $color-gray-lightest;
        }
        &.disabled {
            display: none;
        }
        &.hidden {
            display: none;
        }
        &.active {
            color: $color-primary;
            background: $color-gray-lightest;
        }
    }
}
