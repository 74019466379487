// Typography
@function rem-rounding($number) {
    @return round($number * 10) / 10;
}

@mixin responsive-type($size) {
    font-size: $size;
    //line-height: $line-height-base;

    @include media-breakpoint-up(md) {
        font-size: rem-rounding($size * $font-multiplier-md);
        //line-height: rem-rounding(($size * $font-multiplier-md) * $line-height-base);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-rounding($size * $font-multiplier-lg);
        //line-height: rem-rounding(($size * $font-multiplier-lg) * $line-height-base);
    }
}

@mixin responsive-type-md($size) {
    font-size: $size;
    //line-height: $line-height-base;

    @include media-breakpoint-up(md) {
        font-size: rem-rounding($size * $font-multiplier-md);
        //line-height: rem-rounding(($size * $font-multiplier-md) * $line-height-base);
    }
}

@mixin responsive-type-lg($size) {
    font-size: $size;
    line-height: $line-height-base;

    @include media-breakpoint-up(lg) {
        font-size: rem-rounding($size * $font-multiplier-lg);
        //line-height: rem-rounding(($size * $font-multiplier-lg) * $line-height-base);
    }
}


@mixin text-emphasis-variant($parent, $color) {
    #{$parent} {
        color: $color !important;
    }
    a#{$parent} {
        @include hover-focus {
            color: darken($color, 10%) !important;
        }
    }
}

// Text truncate
// Requires inline-block or block for proper styling

@mixin text-truncate() {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

// CSS image replacement

@mixin text-hide() {
    font: 0 / 0 a;
    text-shadow: none;
    color: transparent;
    background-color: transparent;
    border: 0;
}