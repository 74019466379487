/*
Visibility

For faster mobile-friendly development, use these utility classes for showing and hiding content by device via media query. Also included are utility classes for toggling content when printed.
The .invisible class can be used to toggle only the visibility of an element, meaning its display is not modified and the element can still affect the flow of the document.

Markup: docs/utilities/visibility.hbs

Style guide: Utilities.visibility
*/

.invisible {
    @include invisible();
}

// Responsive visibility utilities

@each $bp in map-keys($grid-breakpoints) {
    .hidden-#{$bp}-up {
        @include media-breakpoint-up($bp) {
            display: none !important;
        }
    }
    .hidden-#{$bp}-down {
        @include media-breakpoint-down($bp) {
            display: none !important;
        }
    }
}


// Print utilities
//
// Media queries are placed on the inside to be mixin-friendly.

.visible-print-block {
    display: none !important;

    @media print {
        display: block !important;
    }
}
.visible-print-inline {
    display: none !important;

    @media print {
        display: inline !important;
    }
}
.visible-print-inline-block {
    display: none !important;

    @media print {
        display: inline-block !important;
    }
}

.hidden-print {
    @media print {
        display: none !important;
    }
}