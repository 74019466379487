// Form validation states
//
// Used in _forms.scss to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation($color) {
    // Color the label and help text
    .form-control-feedback,
    .form-control-label,
    .col-form-label,
    .form-check-label,
    .custom-control {
        color: $color;
    }

    // Set the border and box shadow on specific inputs to match
    .form-control {
        border-color: $color;

        &:focus {
            @include box-shadow($input-box-shadow, 0 0 6px lighten($color, 20%));
        }
    }

    // Set validation states also for addons
    .input-group-addon {
        color: $color;
        background-color: lighten($color, 40%);
        border-color: $color;
    }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-border-focus` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus() {
    &:focus {
        color: $input-color-focus;
        background-color: $input-bg-focus;
        border-color: $input-border-focus;
        outline: none;
    }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

@mixin input-size($parent, $input-height, $padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    #{$parent} {
        height: $input-height;
        padding: $padding-y $padding-x;
        font-size: $font-size;
        line-height: $line-height;
        @include border-radius($border-radius);
    }

    select#{$parent} {
        height: $input-height;
        line-height: $input-height;
    }

    // sass-lint:disable-all
    textarea#{$parent},
    select#{$parent}[multiple] {
        height: auto;
    }
}
