/*
Forms

Provides several form control styles, layout options, and custom components for creating a wide variety of forms.

Markup: docs/core/forms.hbs

Style guide: Core.forms
*/

//
// Textual form controls
//

.form-control {
    display: block;
    width: 100%;
    // // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    // height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-size: $font-size-base;
    line-height: $input-line-height;
    color: $input-color;
    background-clip: padding-box;
    background-color: $input-bg;
    // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
    background-image: none;
    border: 1px solid $input-border-color;

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @if $enable-rounded {
        // Manually use the if/else instead of the mixin to account for iOS override
        border-radius: $input-border-radius;
    } @else {
        // Otherwise undo the iOS default
        border-radius: 0;
    }
    @include transition($input-transition);

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus();

    // Placeholder
    &::placeholder {
        color: $input-color-placeholder;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
        background-color: $input-bg-disabled;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
        &.filename-holder {
            background-color: $input-bg;
            cursor: pointer;
        }
    }

    &:disabled {
        cursor: $cursor-disabled;
    }
    &.danger {
        background: $alert-danger-bg;
        border-color: $color-danger;
    }
    &.warning {
        border-color: $color-warning;
    }
    &.success {
        background: $color-success;
        border-color: $color-success-dark;
    }
}

.filename-holder-overlay {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    z-index: 10;
}

input.hide-spinner::-webkit-outer-spin-button,
input.hide-spinner::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"].hide-spinner {
    -moz-appearance: textfield;
}

.form-group.with-action-button {
    position: relative;
    padding-right: 80px;
    .btn {
        position: absolute;
        top: 0;
        right: 0;
    }
}

label {
    font-family: $font-family-titles;
    font-weight: $font-weight-bold;
    font-size: 1.6rem;
    color: $color-secondary-dark;
}

select.form-control {
    &:not([size]):not([multiple]) {
        $select-border-width: ($border-width * 2);
        height: calc(#{$input-height} + #{$select-border-width});
    }

    &:focus::-ms-value {
        // Suppress the nested default white text on blue background highlight given to
        // the selected option text when the (still closed) <select> receives focus
        // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
        // match the appearance of the native widget.
        // See https://github.com/twbs/bootstrap/issues/19398.
        color: $input-color;
        background-color: $input-bg;
    }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
    display: block;
}


//
// Labels
//

// For use with horizontal and inline forms, when you need the label text to
// align with the form controls.
.col-form-label {
    margin-bottom: 0; // Override the `<label>` default
    padding-top: calc(#{$input-padding-y} - #{$input-btn-border-width} * 2);
    padding-bottom: calc(#{$input-padding-y} - #{$input-btn-border-width} * 2);
}

.col-form-label-lg {
    padding-top: calc(#{$input-padding-y-lg} - #{$input-btn-border-width} * 2);
    padding-bottom: calc(#{$input-padding-y-lg} - #{$input-btn-border-width} * 2);
    font-size: $font-size-lg;
}

.col-form-label-sm {
    padding-top: calc(#{$input-padding-y-sm} - #{$input-btn-border-width} * 2);
    padding-bottom: calc(#{$input-padding-y-sm} - #{$input-btn-border-width} * 2);
    font-size: $font-size-sm;
}


//
// Legends
//

// For use with horizontal and inline forms, when you need the legend text to
// be the same size as regular labels, and to align with the form controls.
.col-form-legend {
    margin-bottom: 0;
    padding-top: $input-padding-y;
    padding-bottom: $input-padding-y;
    font-size: $font-size-base;
}


// Static form control text
//
// Apply class to an element to make any string of text align with labels in a
// horizontal form layout.

.form-control-static {
    margin-bottom: 0; // match inputs if this class comes on inputs with default margins
    padding-top: $input-padding-y;
    padding-bottom: $input-padding-y;
    line-height: $input-line-height;
    border: solid transparent;
    border-width: $input-btn-border-width 0;

    &.form-control-sm,
    &.form-control-lg {
        padding-left: 0;
        padding-right: 0;
    }

    &.text-lg {
        padding-top: $input-padding-y / 2;
        padding-bottom: $input-padding-y / 2;
        font-weight: 300;
    }
}


// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.

.form-control-sm {
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
    @include border-radius($input-border-radius-sm);
}

select.form-control-sm {
    &:not([size]):not([multiple]) {
        height: $input-height-sm;
    }
}

.form-control-lg {
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $font-size-lg;
    @include border-radius($input-border-radius-lg);
}

select.form-control-lg {
    &:not([size]):not([multiple]) {
        height: $input-height-lg;
    }
}


// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
    margin-bottom: $form-group-margin-bottom;
    @include media-breakpoint-up(md) {
        margin-bottom: $form-group-margin-bottom-lg;
    }
    &.input-ean {
        margin-top: $form-group-margin-bottom;
        margin-bottom: 0;
        &:first-child {
            margin-top: 0;
        }
    }

}

.form-text {
    display: block;
    margin-bottom: $form-text-margin-bottom;
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-check {
    display: block;
    position: relative;
    margin-bottom: $form-check-margin-bottom;

    &.disabled {
        .form-check-label {
            color: $color-text-muted;
            cursor: $cursor-disabled;
        }
    }
}

.form-check-label {
    margin-bottom: 0; // Override default `<label>` bottom margin
    padding-left: $form-check-input-gutter;
    font-weight: $font-weight-base;
    cursor: pointer;
}

.form-check-input {
    position: absolute;
    margin-top: $form-check-input-margin-y;
    margin-left: -$form-check-input-gutter;

    &:only-child {
        //position: static;
    }
}

// Radios and checkboxes on same line
.form-check-inline {
    display: inline-block;

    .form-check-label {
        vertical-align: middle;
    }

    + .form-check-inline {
        margin-left: $form-check-inline-margin-x;
    }
}


// Form control feedback states
//
// Apply contextual and semantic states to individual form controls.

.form-control-feedback {
    margin-top: $form-feedback-margin-top;
}

.form-control-success,
.form-control-warning,
.form-control-danger {
    padding-right: ($input-padding-x * 3);
    background-repeat: no-repeat;
    background-position: center right ($input-height / 4);
    background-size: ($input-height / 2) ($input-height / 2);
}

// Form validation states
.has-success {
    @include form-control-validation($color-success-dark);
    label {
        color: $color-success-dark;
    }
    input, textarea {
        color: $color-success-dark;
        background: $color-success-lightest;
        border-color: $color-success-dark;
    }
    .input-group-addon {
        color: $input-group-addon-color;
        background: $input-group-addon-bg;
        border-color: $input-border-color;
    }
    .form-control-success {
        background-image: $form-icon-success;
    }
}

.has-warning {
    @include form-control-validation($color-warning-dark);

}

.has-danger {
    @include form-control-validation($color-danger-dark);

    .form-control-danger {
        background-image: $form-icon-danger;
    }
    label {
        color: $color-danger-dark;
    }
    input, textarea {
        color: $color-danger-dark;
        background: $color-danger-lightest;
        border-color: $color-danger-dark;
    }
    .input-group-addon {
        color: $input-group-addon-color;
        background: $input-group-addon-bg;
        border-color: $input-border-color;
    }
}


// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

    // Because we use flex, the initial sizing of checkboxes is collapsed and
    // doesn't occupy the full-width (which is what we want for xs grid tier),
    // so we force that here.
    .form-check {
        width: 100%;
    }

    // Kick in the inline
    @include media-breakpoint-up(sm) {
        label {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
        }

        // Inline-block all the things for "inline"
        .form-group {
            display: flex;
            flex: 0 0 auto;
            flex-flow: row wrap;
            align-items: center;
            margin-bottom: 0;
        }

        // Allow folks to *not* use `.form-group`
        .form-control {
            display: inline-block;
            width: auto; // Prevent labels from stacking above inputs in `.form-group`
            vertical-align: middle;
        }

        // Make static controls behave like regular ones
        .form-control-static {
            display: inline-block;
        }

        .input-group {
            width: auto;
        }

        .form-control-label {
            margin-bottom: 0;
            vertical-align: middle;
        }

        // Remove default margin on radios/checkboxes that were used for stacking, and
        // then undo the floating of radios and checkboxes to match.
        .form-check {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            margin-top: 0;
            margin-bottom: 0;
        }
        .form-check-label {
            padding-left: 0;
        }
        .form-check-input {
            position: relative;
            margin-top: 0;
            margin-left: 0;
            margin-right: $form-check-input-margin-x;
        }

        // Custom form controls
        .custom-control {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 0;
        }
        .custom-control-indicator {
            display: inline-block;
            position: static;
            margin-right: $form-check-input-margin-x; // Flexbox alignment means we lose our HTML space here, so we compensate.
            vertical-align: text-bottom;
        }

        // Re-override the feedback icon.
        .has-feedback .form-control-feedback {
            top: 0;
        }
    }
}

.label-block {
    font-weight: normal;
}

.label-title {
    display: block;
    font-family: $font-family-titles;
    font-weight: 500;
    color: $color-secondary-dark;
}

.checkbox label, .radio label {
    font-family: $font-family-sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    color: $color-body-text;
}

.g-recaptcha {
    @include media-breakpoint-down(xs) {
        transform: scale(.83);
        transform-origin: 0 0;
    }
}
