/*
Clearfix

Easily clear floats by adding `.clearfix` to the parent element. Utilizes the micro clearfix as popularized by Nicolas Gallagher. Can also be used as a mixin.

Updated clearfix uses `display: block;` instead of `display: table;`

More info: http://cssmojo.com/the-very-latest-clearfix-reloaded/

Markup: docs/utilities/clearfix.hbs

Style guide: Utilities.clearfix
*/

.clearfix {
    @include clearfix();
}