@mixin transition($transition...) {
    @if $enable-transitions {
        transition: $transition;
    }
}

@mixin box-shadow($shadow...) {
    @if $enable-shadows {
        box-shadow: $shadow;
    }
}

// Center-align a block level element

@mixin center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Utilities
@import "border-radius";
@import "breakpoints";
@import "hover";
@import "image";
@import "icon";
@import "resize";
@import "screen-reader";
@import "size";
@import "sprite";
@import "reset-filter";
@import "reset-text";
@import "tab-focus";
@import "text";
@import "transforms";
@import "visibility";

// Components
@import "alert";
@import "badge";
@import "box";
@import "buttons";
@import "card";
@import "forms";
@import "list";
@import "list-group";
@import "nav-divider";
@import "pagination";
@import "panel";
@import "table-row";

// Skins
@import "background";
@import "border-radius";
@import "gradients";

// Layout
@import "clearfix";
@import "grid-framework";
@import "grid";
@import "float";
