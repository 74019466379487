/*
Sizing

Easily make an element as wide or as tall (relative to its parent) with our width and height utilities. Includes support for 25%, 50%, 75%, and 100% by default.

Markup: docs/utilities/sizing.hbs

Style guide: Utilities.sizing
*/


@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (width: w, height: h) {
            @each $size, $length in $sizes {
                .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
            }
            .#{$abbrev}#{$infix}-auto { #{$prop}: auto !important; }
        }

    }
}

.mw-100 { max-width: 100% !important; }
.minh-100 { min-height: 100% !important; }
.mh-100 { max-height: 100% !important; }
