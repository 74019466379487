/*
Float

Helper classes for floating element targetted to certain breakpoints

Markup: docs/utilities/float.hbs

Style guide: Utilities.float
*/

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .float#{$infix}-left  { @include float-left; }
        .float#{$infix}-right { @include float-right; }
        .float#{$infix}-none  { @include float-none; }
    }
}