/*
Position

Position utilities are helpful for quickly placing a component outside the normal document flow. Choose from a handful of fixed or sticky position classes as needed.

Markup: docs/utilities/position.hbs

Style guide: Utilities.position
*/

.fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindex-fixed;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zindex-fixed;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
}

.sticky {
    top: 0;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        .position#{$infix}-absolute { position: absolute !important; }
        .position#{$infix}-relative { position: relative !important; }
        .position#{$infix}-static { position: static !important; }
    }
}
