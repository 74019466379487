.comments-section {
    position: relative;
    padding-top: 3.6rem;
    &::before {
        position: absolute;
        top: 3.6rem;
        left: 0;
        right: 1rem;
        height: 5rem;
        background: linear-gradient(to bottom, rgba(245, 245, 245, 1), rgba(245,245,245,0));
        z-index: 1;
        content: '';
    }
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 1rem;
        height: 5rem;
        background: linear-gradient(to bottom, rgba(245,245,245,0), rgba(245, 245, 245, 1));
        z-index: 1;
        content: '';
    }
}

.comments-pane {
    position: relative;
    max-height: 55rem;
    padding: 7rem 2.4rem 2rem;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 1rem;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: .8rem;
        background-color: $color-violet;
    }
}

.comment {
    position: relative;
    padding-bottom: 5rem;
    .comment-header {
        margin-bottom: 1rem;
        color: $color-violet;
        font-weight: $font-weight-bold;
    }
    .comment-body {
        display: inline-block;
        max-width: 53rem;
        padding: 1.6rem;
        border: 1px solid $color-gray-400;
        text-align: left;
        background: $color-white;
        border-radius: .8rem;
    }
    .comment-footer {
        position: absolute;
        left: 0;
        bottom: 2rem;
        font-size: 1.5rem;
        color: #2B2828;
    }
    &.user {
        text-align: right;
        .comment-body {
            border-color: $color-violet;
            color: $color-white;
            background: $color-violet;
        }
        .comment-footer {
            left: auto;
            right: 0;
        }
    }
}

.chat-box {
    margin: 0;
    position: relative;
    textarea {
        min-height: 9.2rem;
        height: 9.2rem;
    }
    .chat-box-button {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 4.8rem;
        height: 4.8rem;
        padding: 1.2rem 0;
        text-align: center;
        border-radius: 50%;
        transform: translateY(-50%);
    }
}
