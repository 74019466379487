.cta {
    padding: $cta-padding-y $cta-padding-x;
    background: $cta-bg;
    color: $cta-color;
    @include border-radius($border-radius);
    &.has-icon {
        position: relative;
        padding-left: $cta-icon-padding;
        .cta-icon {
            display: block;
            position: absolute;
            top: 50%;
            left: $cta-padding-x;
            width: $rounded-icon-size;
            height: $rounded-icon-size;
            padding-top: 1.05rem;
            color: $cta-bg;
            background: $color-lightest;
            border: 2px solid $color-lightest;
            @include border-radius(50%);
            text-align: center;
            transform: translateY(-50%);
            .action-icon-outline & {
                background: transparent;
            }
        }
        &.icon-right {
            padding-left : $cta-padding-x;
            padding-right: $cta-icon-padding;
            .cta-icon {
                left: auto;
                right: $cta-padding-x;
                color: $color-warning;
            }
        }

    }
    &.is-default {
        color: $color-gray-800;
        background: $color-default;
        .cta-icon {
            border-color: $color-default;
            background: $color-default;
            text-align: right;
        }
    }
    &.js-card-clickable {
        .icon {
            transition: transform .32s;
        }
        a {
            color: inherit;
        }
        &:hover {
            .icon {
                transform: translateX(5px);
            }
        }
    }
}

.cta-title {
    margin-bottom: 0;
    font-size: $cta-title-size;
    line-height: $cta-title-line-height;
    color: inherit;
    @include media-breakpoint-up(md) {
        font-size: $cta-title-size-lg;
        line-height: $cta-title-line-height-lg;
    }
}
