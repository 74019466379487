/*
Tooltip

Inspired by the Tipsy jQuery plugin written by Jason Frame. Tooltips are an updated version, which don’t rely on images, use CSS3 for animations, and data-attributes for local title storage.

Markup: docs/components/tooltip.hbs

Style guide: Components.tooltip
*/

/* Tooltip: Base class
========================================================== */

.tooltip {
    display: block;
    position: absolute;
    // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.
    @include reset-text();
    font-size: $font-size-sm;
    // Allow breaking very long words so they don't overflow the tooltip's bounds
    word-wrap: break-word;
    opacity: 0;
    z-index: $zindex-tooltip;

    &.show { opacity: $tooltip-opacity; }

    &.tooltip-top,
    &.bs-tether-element-attached-bottom {
        margin-top: -$tooltip-margin;
        padding: $tooltip-arrow-width 0;

        .tooltip-inner::before {
            bottom: 0;
            left: 50%;
            margin-left: -$tooltip-arrow-width;
            content: "";
            border-top-color: $tooltip-arrow-color;
            border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
        }
    }
    &.tooltip-right,
    &.bs-tether-element-attached-left {
        margin-left: $tooltip-margin;
        padding: 0 $tooltip-arrow-width;

        .tooltip-inner::before {
            top: 50%;
            left: 0;
            margin-top: -$tooltip-arrow-width;
            content: "";
            border-right-color: $tooltip-arrow-color;
            border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
        }
    }
    &.tooltip-bottom,
    &.bs-tether-element-attached-top {
        margin-top: $tooltip-margin;
        padding: $tooltip-arrow-width 0;

        .tooltip-inner::before {
            top: 0;
            left: 50%;
            margin-left: -$tooltip-arrow-width;
            content: "";
            border-bottom-color: $tooltip-arrow-color;
            border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
        }
    }
    &.tooltip-left,
    &.bs-tether-element-attached-right {
        margin-left: -$tooltip-margin;
        padding: 0 $tooltip-arrow-width;

        .tooltip-inner::before {
            top: 50%;
            right: 0;
            margin-top: -$tooltip-arrow-width;
            content: "";
            border-left-color: $tooltip-arrow-color;
            border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
        }
    }
}

/* Tooltip: inner contents
========================================================== */

.tooltip-inner {
    max-width: $tooltip-max-width;
    padding: $tooltip-padding-y $tooltip-padding-x;
    text-align: center;
    color: $tooltip-color;
    background-color: $tooltip-bg;
    @include border-radius($border-radius);

    &::before {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
}