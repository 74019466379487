// Badges

@mixin badge-variant($name, $value) {
    .badge-#{$name} {
        color: map-get($value, color-dark);
        background-color: map-get($value, color-lightest);
        &.is-highlighted {
            background-color: map-get($value, color);
        }
        &.has-hover {
            @include hover-focus {
                background-color: map-get($value, color-dark);
            }
        }
    }
}
