/*
Grid

Mobile-first flexbox grid system for building layouts of all shapes and sizes. It’s based on a 12 column layout and has multiple tiers, one for each media query range. You can use it with Sass mixins or our predefined classes.

This grid system uses a series of containers, rows, and columns to layout and align content. It’s built with flexbox and is fully responsive. Below is an example and an in-depth look at how the grid comes together.

Markup: docs/core/grid.hbs

Style guide: Core.grid
*/

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
    .container {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        padding-left: 2.4rem;
        padding-right:  2.4rem;
        @include media-breakpoint-up(lg) {
            padding-left: ($grid-gutter-width-base /2);
            padding-right:  ($grid-gutter-width-base /2);
        }
    }
}

.container.container-xs {
    width: 100%;
    max-width: 410px;
}

.container.container-sm {
    max-width: 670px;
}

.container.container-md {
    max-width: 820px;
}

.container.container-lg {
    max-width: 924px;
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

@if $enable-grid-classes {
    .container-fluid {
        @include make-container();
    }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
    .row {
        @include make-row();
    }

    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    .no-gutters {
        margin-left: 0;
        margin-right: 0;

        > .col,
        > [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
    @include make-grid-columns();
}

/*
	-----------------------------------------
	5 column grid
	-----------------------------------------
*/

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-left: 1px;
    padding-right: 15px;
}

.col-xs-5ths {
    float: left;
    width: 20%;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        float: left;
        width: 20%;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        float: left;
        width: 20%;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        float: left;
        width: 20%;
    }
}

/*
	-----------------------------------------
	centered grid
	-----------------------------------------
*/

.centered-grid {
    display: block;
    font-size: 0;
    letter-spacing: 0 !important;
    text-align: center;
    .grid-item {
        display: -moz-inline-stack;
        display: inline-block;
        float: none;
        font-size: $font-size-base;
        vertical-align: top;
    }
}
