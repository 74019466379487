/*
Navbar

The navbar is a wrapper that positions branding, navigation, and other elements in a concise header. It’s easily extensible and, thanks to the Collapse plugin, can easily integrate responsive behaviors.

Markup: docs/components/navbar.hbs

Style guide: Components.navbar
*/

/* NAVBAR: Base class
========================================================== */
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: $navbar-padding-y $navbar-padding-x;
    &.nav-primary {
        padding: 0;
    }
}


/* NAVBAR: Brand
========================================================== */
//
// Used for brand, project, or site names.

.navbar-brand {
    display: inline-block;
    margin-right: $navbar-padding-x;
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: $font-size-lg;
    line-height: inherit;
    white-space: nowrap;

    @include hover-focus {
        text-decoration: none;
    }
}


/* NAVBAR: Nav
========================================================== */
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
    display: flex;
    flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    .nav-link {
        padding-left: 0;
        padding-right: 0;
    }
}


// Navbar text
//
//

.navbar-text {
    display: inline-block;
    padding-top:    .425rem;
    padding-bottom: .425rem;
}


/* NAVBAR: Responsive navbar
========================================================== */
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse JavaScript plugin.

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
    align-self: flex-start; // Prevent toggler from growing to full width when it's the only visible navbar child
    padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
    font-size: $navbar-toggler-font-size;
    line-height: 1;
    background: transparent; // remove default button style
    border: $border-width solid transparent; // remove default button style
    @include border-radius($navbar-toggler-border-radius);

    @include hover-focus {
        text-decoration: none;
    }
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    vertical-align: middle;
}

// Use `position` on the toggler to prevent it from being auto placed as a flex
// item and allow easy placement.
.navbar-toggler-left {
    position: absolute;
    left: $navbar-padding-x;
}
.navbar-toggler-right {
    position: absolute;
    right: $navbar-padding-x;
}

// Generate series of `.navbar-toggleable-*` responsive classes for configuring
// where your navbar collapses.
.navbar-toggleable {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        &#{$infix} {
            // Fix for flex navbar padding conflicting with container
            padding-left: 0;
            padding-right: 0;

            @include media-breakpoint-down($breakpoint) {
                .navbar-nav {
                    .dropdown-menu {
                        position: static;
                        float: none;
                    }
                }

                > .container {
                    /*
                    padding-left: 0;
                    padding-right: 0;
                    */
                }
            }

            @include media-breakpoint-up($next) {
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;

                .navbar-nav {
                    flex-direction: row;

                    .nav-link {
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                    }
                }

                // For nesting containers, have to redeclare for alignment purposes
                > .container {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                }

                // scss-lint:disable ImportantRule
                .navbar-collapse {
                    display: flex;
                    width: 100%;
                }
                // scss-lint:enable ImportantRule

                .navbar-toggler {
                    display: none;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .navbar-toggleable-md {
        .container {
            width: 100%;
        }
    }
}


/*
	-----------------------------------------
	    Nav account
	-----------------------------------------
*/

.navbar-account {
    display: none;
    padding: 0;
    border-radius: $border-radius;
    @include media-breakpoint-down(sm) {
        margin-bottom: 30px;;
        background: $color-gray-100;
        border: 1px solid $card-border-color;
    }
    @include media-breakpoint-up(md) {
        display: block;
    }
    .navbar-header {
        padding-bottom: 1rem;
        color: $color-violet;
        font-weight: 700;
    }
    .navbar-collapse {
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
    .navbar-toggler {
        position: absolute;
        top: 1.2rem;
        right: 1.5rem;
        -webkit-transition: all .4s;
        -moz-transition: all .4s;
        -ms-transition: all .4s;
        -o-transition: all .4s;
        transition: all .4s;
    }
    .navbar-nav {
        background: $color-lightest;
        border-top: 1px solid $card-border-color;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        @include media-breakpoint-up(md) {
            display: block;
            border: none;
        }
        .nav-link {
            position: relative;
            padding: 1.5rem 3rem 1.5rem 1.5rem;
            font-family: $font-family-titles;
            font-size: 1.6rem;
            font-weight: $font-weight-bold;
            color: $color-gray-700;
            border-top: 1px solid $card-border-color;
            -webkit-transition: all .4s;
            -moz-transition: all .4s;
            -ms-transition: all .4s;
            -o-transition: all .4s;
            transition: all .4s;
            @include media-breakpoint-up(md) {
                padding: 1.5rem 3rem 1.5rem 0;
            }
            &:hover, &:active, &:focus {
                color: $color-purple;
            }
            .icon {
                position: absolute;
                top: 50%;
                right: 1.5rem;
                transform: translateY(-50%);
                color: $color-violet;
                visibility: hidden;
                @include media-breakpoint-up(md) {
                    right: 0;
                }
            }
            &.active {
                color: $color-violet;
                .icon {
                    visibility: visible;
                }
            }
        }

        .nav-item:first-child .nav-link {
            border-top: none;
        }
        .nav-item:last-child .nav-link {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}

.navbar-header {
    position: relative;
    padding: 1.5rem 4rem 1.5rem 1.5rem;
    font-family: $font-family-titles;
    font-weight: 500;
    color: $color-gray;
    cursor: pointer;
    &[aria-expanded="true"] {
        .navbar-toggler {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }
}
