/*
Borders

Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons, or any other element.

Markup: docs/utilities/borders.hbs

Style guide: Utilities.borders
*/

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }


.border-1        { border: 1px solid $border-default-color !important; }
.border-top-1    { border-top: 1px solid $border-default-color !important; }
.border-right-1  { border-right: 1px solid $border-default-color !important; }
.border-bottom-1 { border-bottom: 1px solid $border-default-color !important; }
.border-left-1   { border-left: 1px solid $border-default-color !important; }

//
// Border-radius
//
.border-gray-100 {
    border-color: $color-gray-100 !important;
}

.border-gray-200 {
    border-color: $color-gray-200 !important;
}

.border-gray-300 {
    border-color: $color-gray-300 !important;
}

.rounded {
    @include border-radius($border-radius);
}
.rounded-top {
    @include border-top-radius($border-radius);
}
.rounded-right {
    @include border-right-radius($border-radius);
}
.rounded-bottom {
    @include border-bottom-radius($border-radius);
}
.rounded-left {
    @include border-left-radius($border-radius);
}

.rounded-circle {
    border-radius: 50%;
}

.rounded-0 {
    border-radius: 0;
}
