@mixin _assert-ascending($map, $map-name) {
    $prev-key: null;
    $prev-num: null;
    @each $key, $num in $map {
        @if $prev-num == null {
            // Do nothing
        } @else if not comparable($prev-num, $num) {
            @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
        } @else if $prev-num >= $num {
            @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
        }
        $prev-key: $key;
        $prev-num: $num;
    }
}

// Replace `$search` with `$replace` in `$string`
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin _assert-starts-at-zero($map) {
    $values: map-values($map);
    $first-value: nth($values, 1);
    @if $first-value != 0 {
        @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
    }
}

/* CORE: Spacing
========================================================== */
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$grid-gutter-width:          1.2rem !default;
$grid-gutter-width-sm:       1.6rem !default;
$grid-gutter-width:          1.2rem !default;
$grid-gutter-width-md:       2.4rem !default;
$grid-gutter-width-xl:       3.6rem !default;
$spacer:   $grid-gutter-width / 2 !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;

$spacers: (
        0: 0,
        1: ($grid-gutter-width /2),             // 6
        2: ($grid-gutter-width-md / 3),         // 8
        3: $grid-gutter-width,                  // 12
        4: ($grid-gutter-width-md / 1.5),       // 16
        5: $grid-gutter-width-md,               // 24
        6: $grid-gutter-width-xl,               // 36
        7: ($grid-gutter-width-md * 2),         // 48
        8: ($grid-gutter-width-xl * 2),         // 72
        9: ($grid-gutter-width-md * 4),         // 96
        10: ($grid-gutter-width-xl * 4),        // 144
) !default;

$border-width: 1px !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
) !default;


/* CORE: Colors
========================================================== */
//
// Grayscale and theme colors for use across framework.

/* MAIN COLORS */
/** @color purple lightest */
$color-purple-lightest:         #DDD3F1 !default;
/** @color purple light */
$color-purple-light:            #9671A8 !default;
/** @color purple */
$color-purple:                  #5A2C71 !default;
/** @color purple dark */
$color-purple-dark:             #401556 !default;

/** @color violet lightest */
$color-violet-lightest:         #FFD6F4 !default;
/** @color violet light */
$color-violet-light:            #D365B5 !default;
/** @color violet */
$color-violet:                  #A3117B !default;
/** @color violet dark */
$color-violet-dark:             #76115A !default;

/** @color red lightest */
$color-red-lightest:            #FBD1D1 !default;
/** @color red light */
$color-red-light:               #E2606A !default;
/** @color red */
$color-red:                     #C40046 !default;
/** @color red dark */
$color-red-dark:                #93103D !default;

/** @color orange lightest */
$color-orange-lightest:         #FFE2BD !default;
/** @color orange light */
$color-orange-light:            #FFA93B !default;
/** @color orange */
$color-orange:                  #E98300 !default;
/** @color orange dark */
$color-orange-dark:             #C5590A !default;

/** @color yellow lightest */
$color-yellow-lightest:         #FFF2BF !default;
/** @color yellow light */
$color-yellow-light:            #FFD26A !default;
/** @color yellow */
$color-yellow:                  #FFCC00 !default;
/** @color yellow dark */
$color-yellow-dark:             #D0970A !default;

/** @color cyan */
$color-cyan-lightest:           #D1FDFD !default;
/** @color cyan light */
$color-cyan-light:              #97EEEE !default;
/** @color cyan */
$color-cyan:                    #66CCCC !default;
/** @color cyan dark */
$color-cyan-dark:               #13A0AC !default;

/** @color blue lightest */
$color-blue-lightest:           #BFE8FF !default;
/** @color blue light */
$color-blue-light:              #73BFE8 !default;
/** @color blue */
$color-blue:                    #2F7195 !default;
/** @color blue dark */
$color-blue-dark:               #0C5279 !default;

/** @color grass lightest */
$color-grass-lightest:          #E4FEB0 !default;
/** @color grass light */
$color-grass-light:             #B9E462 !default;
/** @color grass */
$color-grass:                   #99CC33 !default;
/** @color grass dark */
$color-grass-dark:              #649404 !default;

/** @color green lightest */
$color-green-lightest:          #B5EBDE !default;
/** @color green light */
$color-green-light:             #58B5A0 !default;
/** @color green */
$color-green:                   #177F67 !default;
/** @color green dark */
$color-green-dark:              #05503F !default;

/** @color copper lightest */
$color-copper-lightest:         #FFE6B4 !default;
/** @color copper light */
$color-copper-light:            #F1BF5B !default;
/** @color copper */
$color-copper:                  #CC9933 !default;
/** @color copper dark */
$color-copper-dark:             #946007 !default;

/** @color brown lightest*/
$color-brown-lightest:          #F9CAA7 !default;
/** @color brown light */
$color-brown-light:             #AA7853 !default;
/** @color brown */
$color-brown:                   #62422A !default;
/** @color brown dark */
$color-brown-dark:              #351F0E !default;

/**
 * @color White
 * @section Grayscales
 */
$color-white:           #fff !default;
/**
* @color gray 100
* @section Grayscales
*/
$color-gray-100:        #F5F5F5 !default;
/**
* @color gray 200
* @section Grayscales
*/
$color-gray-200:        #EFEEED !default;
/**
* @color gray 300
* @section Grayscales
*/
$color-gray-300:        #DFDCD8 !default;
/**
* @color gray 400
* @section Grayscales
*/
$color-gray-400:        #B7B7B7 !default;
/**
* @color gray 500
* @section Grayscales
*/
$color-gray-500:        #ADA9A7 !default;
/**
* @color gray 600
* @section Grayscales
*/
$color-gray-600:        #968D88 !default;
/**
* @color gray 700
* @section Grayscales
*/
$color-gray-700:        #655E5B !default;
/**
* @color gray 800
* @section Grayscales
*/
$color-gray-800:        #564948 !default;
/**
* @color gray 900
* @section Grayscales
*/
$color-gray-900:        #2B2828 !default;
/**
* @color black
* @section Grayscales
*/
$color-black:           #000000 !default;


$color-lightest:        #FFF;
$color-darkest:         #000;

// Shades of gray
$color-gray:            #968E88;
$color-gray-light:      #636C72;
$color-gray-lighter:    #E7E7E7;
$color-gray-lightest:   #EFEEED;
$color-gray-dark:       $color-gray-900;

// Theme colours
$color-primary:         $color-violet;
$color-primary-light:   #C4276E;
$color-primary-lighter: #B24B96;
$color-primary-lightest: #F3AADF;
$color-primary-dark:    $color-violet-dark;

$color-secondary:       $color-purple;
$color-secondary-light: #D9C4E4;
$color-secondary-lightest: #EFE7F4;
$color-secondary-dark:  $color-purple-dark;

$color-tertiary:       #E98300;
$color-tertiary-light: #FC0;
$color-tertiary-dark:  #BC6900;

// message colors
$color-success:                 $color-green;
$color-success-light:           $color-green-light;
$color-success-lightest:        $color-green-lightest;
$color-success-dark:            $color-green-dark;

$color-info:                    $color-blue;
$color-info-light:              $color-blue-light;
$color-info-lightest:           $color-blue-lightest;
$color-info-dark:               $color-blue-dark;

$color-warning:                 $color-orange;
$color-warning-light:           $color-orange-light;
$color-warning-lightest:        $color-orange-lightest;
$color-warning-dark:            $color-orange-dark;

$color-danger:                  $color-red;
$color-danger-light:            $color-red-light;
$color-danger-lightest:         $color-red-lightest;
$color-danger-dark:             $color-red-dark;

$message-colors: () !default;
$message-colors: map-merge((
        "success": (
                "color-lightest": $color-success-lightest,
                "color-light": $color-success-light,
                "color": $color-success,
                "color-dark": $color-success-dark,
        ),
        "warning": (
                "color-lightest": $color-warning-lightest,
                "color-light": $color-warning-light,
                "color": $color-warning,
                "color-dark": $color-warning-dark,
        ),
        "info": (
                "color-lightest": $color-info-lightest,
                "color-light": $color-info-light,
                "color": $color-info,
                "color-dark": $color-info-dark,
        ),
        "danger": (
                "color-lightest": $color-danger-lightest,
                "color-light": $color-danger-light,
                "color": $color-danger,
                "color-dark": $color-danger-dark,
        )
), $message-colors);

$color-default:         #EFEEED;
$color-default-light:   #F2F2F2;
$color-default-dark:    #8C8C8C;

$color-highlight:       #0C6792;
$color-highlight-lighter: #53AAD4;
$color-highlight-light: #BDEAFF;

$color-inverse:         $color-gray-dark;



// Pricing
$color-price:           #449C44;

// Body colours
$color-body-bg:         $color-lightest;
$color-body-text:       $color-gray-700;

$color-text-muted:      $color-gray-light;

// Links
$color-link:            $color-primary !default;
$color-link-hover:      $color-primary-dark !default;

// Decorations
$decoration-link:       none !default;
$decoration-link-hover: none !default;

/* CORE: General options
========================================================== */
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

/* CORE: Fonts & type
========================================================== */
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:        'Maven Pro', Arial, sans-serif !default;
$font-family-titles:            'Maven Pro', Arial, sans-serif !default;
$font-family-monospace:         Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:              $font-family-sans-serif !default;

// Set $font-size-base to `1rem` to have a base font-size of `16px.

$font-scale-base:               62.5%; // = 10px in normal situation

$font-size-base:                1.7rem !default; // `1.4rem` = `14px`
$font-size-lg:                  2rem !default;
$font-size-md:                  1.5rem !default;
$font-size-sm:                  1.2rem !default;
$font-size-xs:                  1.1rem !default;

$font-multiplier-md:            1 !default;
$font-multiplier-lg:            1.2 !default;

$font-weight-normal:            400 !default;
$font-weight-medium:            500 !default;
$font-weight-bold:              700 !default;

$font-weight-base:              $font-weight-normal !default;

$line-height-base:              1.5 !default;
$line-height-lg:                (4 / 3) !default;
$line-height-sm:                1.5 !default;

$font-size-h1:                  3.2rem !default;
$line-height-h1:                3.6rem !default;
$font-size-h1-lg:               3.8rem !default;
$line-height-h1-lg:             4.4rem !default;
$font-weight-h1:                $font-weight-bold;
$color-h1:                      $color-purple;
$margin-bottom-h1:              2.4rem !default;

$font-size-h2:                  3rem !default;
$line-height-h2:                3.4rem !default;
$font-size-h2-lg:               3.4rem !default;
$line-height-h2-lg:             3.8rem !default;
$color-h2:                      $color-violet;
$margin-bottom-h2:              1.6rem !default;

$font-size-h3:                  2.8rem !default;
$line-height-h3:                3.2rem !default;
$font-size-h3-lg:               3rem !default;
$line-height-h3-lg:             3.4rem !default;
$color-h3:                      $color-red;
$margin-bottom-h3:              1.6rem !default;

$font-size-h4:                  2.6rem !default;
$line-height-h4:                3rem !default;
$color-h4:                      $color-purple;
$margin-bottom-h4:              1.2rem !default;

$font-size-h5:                  2.2rem !default;
$line-height-h5:                2.6rem !default;
$font-size-h5-lg:               2.4rem !default;
$line-height-h5-lg:             2.8rem !default;
$color-h5:                      $color-violet;
$margin-bottom-h5:              1.2rem !default;

$font-size-h6:                  2rem !default;
$line-height-h6:                2.4rem !default;
$color-h6:                      $color-red;
$margin-bottom-h6:              1.2rem !default;

$headings-margin-bottom:        ($spacer / 2) !default;
$headings-font-family:          inherit !default;
$headings-font-weight:          700 !default;
$headings-line-height:          1.1 !default;
$headings-color:                inherit !default;

$lead-margin-bottom:            2.4rem !default;
$lead-font-size:                2.0rem !default;
$lead-line-height:              2.8rem !default;
$lead-font-size-lg:             2.2rem !default;
$lead-line-height-lg:           3rem !default;
$lead-font-weight:              $font-weight-normal !default;
$lead-color:                    $color-gray-700 !default;

$display-h1-size:               6rem !default;
$display-h2-size:               5.5rem !default;
$display-h3-size:               4.5rem !default;
$display-h4-size:               3.5rem !default;

$display-h1-weight:             300 !default;
$display-h2-weight:             300 !default;
$display-h3-weight:             300 !default;
$display-h4-weight:             300 !default;
$display-line-height:           $headings-line-height !default;

$lead-font-size:                1.8rem !default;
$lead-font-weight:              400 !default;
$lead-color:                    $color-body-text;

$small-font-size:               1.2rem !default;

$font-weight-dt:                $font-weight-bold !default;

$blockquote-small-color:        $color-secondary-dark !default;
$blockquote-font-size:          1.7rem !default;
$blockquote-font-style:         normal;
$blockquote-color:              $color-secondary;
$blockquote-border-color:       $color-gray-lighter !default;
$blockquote-border-width:       1rem !default;

$mark-padding:                  .2em !default;

$list-inline-padding:           5px !default;

/* CORE: General components
========================================================== */
//
// Define common padding and border radius sizes and more.

$border-default-color:          #B7B7B7;

$border-radius:                 .4rem !default;
$border-radius-lg:              .8rem !default;
$border-radius-sm:              .2rem !default;

$component-active-color:        $color-lightest !default;
$component-active-bg:           $color-primary !default;

$caret-width:                   .3em !default;

$transition-base:               all .2s ease-in-out !default;
$transition-fade:               opacity .15s linear !default;
$transition-collapse:           height .35s ease !default;

$hr-border-color:               $border-default-color !default;
$hr-border-width:               $border-width !default;

/* CORE: Tables
========================================================== */
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            1rem 2rem !default;
$table-sm-cell-padding:         .3rem !default;

$table-bg:                      transparent !default;

$table-inverse-bg:              $color-gray-dark !default;
$table-inverse-color:           $color-body-bg !default;

$table-bg-accent:               rgba($color-darkest,.05) !default;
$table-bg-hover:                rgba($color-darkest,.075) !default;
$table-bg-active:               $table-bg-hover !default;

$table-head-bg:                 $color-gray-lighter !default;
$table-head-color:              $color-gray !default;
$table-border-color:            $color-gray-lighter !default;
$table-border-width:            $border-width !default;


$cursor-disabled:               not-allowed !default;

/* CORE: BUTTONS
========================================================== */
//
// For each of the buttons, define text, background and border color.

$btn-padding-x:                 1.5rem !default;
$btn-padding-y:                 1.3rem !default;
$btn-icon-only-x:               8px !default;
$btn-icon-only-y:               $btn-padding-y !default;
$btn-line-height:               1.25 !default;
$btn-font-weight:               500 !default;
$btn-box-shadow:                none;
$btn-focus-box-shadow:          0 0 1px 1px rgba($color-primary, 1) !default;
$btn-active-box-shadow:         inset 0 3px 5px rgba($color-darkest,.125) !default;

$btn-default-color:             $color-lightest !default;
$btn-default-bg:                $color-gray-dark !default;
$btn-default-border:            $color-gray-dark !default;

$btn-negative-color:            #333 !default;
$btn-negative-bg:               $color-lightest !default;
$btn-negative-border:           $color-lightest !default;

$btn-primary-color:             $color-lightest !default;
$btn-primary-bg:                $color-primary !default;
$btn-primary-border:            $btn-primary-bg !default;

$btn-secondary-color:           $color-lightest !default;
$btn-secondary-bg:              $color-secondary !default;
$btn-secondary-border:          $color-secondary !default;

$btn-info-color:                $color-lightest !default;
$btn-info-bg:                   $color-info !default;
$btn-info-border:               $btn-info-bg !default;

$btn-success-color:             $color-lightest !default;
$btn-success-bg:                $color-success !default;
$btn-success-border:            $btn-success-bg !default;

$btn-warning-color:             $color-lightest !default;
$btn-warning-bg:                $color-warning !default;
$btn-warning-border:            $btn-warning-bg !default;

$btn-danger-color:              $color-lightest !default;
$btn-danger-bg:                 $color-danger !default;
$btn-danger-border:             $btn-danger-bg !default;

$btn-link-disabled-color:       $color-gray-light !default;

$btn-border-width:              .2rem !default;
$btn-icon-offset:               4.8rem !default;
$btn-line-height:               1.8rem !default;
$btn-font-size:                 1.6rem !default;
$btn-font-weight:               $font-weight-bold !default;
$btn-box-shadow:                inset 0 1px 0 rgba($color-lightest,.15), 0 1px 1px rgba($color-darkest,.075) !default;
$btn-focus-box-shadow:          0 0 0 2px rgba($color-primary, .25) !default;
$btn-active-box-shadow:         inset 0 3px 5px rgba($color-darkest,.125) !default;

$btn-padding-x-sm:              1.1rem !default;
$btn-padding-y-sm:              1.1rem !default;
$btn-icon-offset-sm:            4.4rem !default;
$btn-line-height-sm:            $btn-line-height !default;

$btn-padding-x-lg:              2.3rem !default;
$btn-padding-y-lg:              2.3rem !default;
$btn-icon-offset-lg:            5.6rem !default;
$btn-line-height-lg:            $btn-line-height !default;

$btn-block-spacing-y:           .5rem !default;

$btn-icon-padding-x:            .5rem !default;
$btn-icon-padding-y:            .5rem !default;

$btn-block-spacing-y:           .5rem !default;

$btn-icon-padding-x:            10px !default;
$btn-icon-padding-y:            9px !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:             $border-radius !default;
$btn-border-radius-lg:          $border-radius-lg !default;
$btn-border-radius-sm:          $border-radius !default;

$btn-transition:                all .2s ease-in-out !default;

/* CORE: Action buttons
========================================================== */
//
// Customizes the action buttons
$action-btn-border-width:       .2rem !default;
$action-btn-icon-size:          4.8rem !default;
$action-btn-icon-line-height:   4.2rem !default;

$action-btn-colors: () !default;
$action-btn-colors: map-merge((
        "violet": (
                "color": $color-violet,
                "color-dark": $color-violet-dark,
        ),
        "gray": (
                "color": $color-gray-800,
                "color-dark": $color-gray-900,
        ),
        "white": (
                "color": $color-lightest,
                "color-dark": $color-lightest,
        )
), $action-btn-colors);

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:             10rem !default;
$dropdown-padding-y:             .5rem !default;
$dropdown-margin-top:            -1px !default;
$dropdown-bg:                    $color-lightest !default;
$dropdown-border-color:          $color-gray-400 !default;
$dropdown-border-width:          $border-width !default;
$dropdown-divider-bg:            $color-gray-lighter !default;
$dropdown-box-shadow:            0 .5rem 1rem rgba($color-darkest,.175) !default;

$dropdown-link-color:            $color-gray-dark !default;
$dropdown-link-hover-color:      darken($color-gray-dark, 5%) !default;
$dropdown-link-hover-bg:         $color-gray-lightest !default;

$dropdown-link-active-color:     $component-active-color !default;
$dropdown-link-active-bg:        $component-active-bg !default;

$dropdown-link-disabled-color:   $color-gray-light !default;

$dropdown-item-padding-x:        1.5rem !default;

$dropdown-header-color:          $color-gray-light !default;


/* CORE: Forms
========================================================== */

$input-padding-x:               1.1rem !default;
$input-padding-y:               1.4rem !default;
$input-line-height:             1.125 !default;
$input-bottom-margin:           0 !default;

$input-font-size:               $font-size-md !default;
$input-font-weight:             500 !default;
$input-bg:                      $color-lightest !default;
$input-color-disabled:          $color-gray-400 !default;
$input-bg-disabled:             $color-gray-100 !default;

$input-icon-color:              $color-gray-500 !default;

$input-color:                   $color-gray-700 !default;
$input-color-focus:             $color-gray-800 !default;
$input-border-width:            $border-width !default;
$input-border-color:            $color-gray-400 !default;
$input-border-focus:            $color-gray-500 !default;
$input-border-radius:           $border-radius;
$input-btn-border-width:        $border-width !default; // For form controls and buttons
$input-box-shadow:              0 1px 1px rgba($color-darkest,.075) !default;

$input-border-width:           .1rem !default;
$input-border-radius:           $border-radius !default;
$input-border-radius-lg:        $border-radius-lg !default;
$input-border-radius-sm:        $border-radius !default;

$input-focus-bg:                $input-bg !default;
$input-focus-border-color:      $color-gray-800 !default;
$input-focus-color:             $input-color !default;
$input-btn-focus-width:         .2rem !default;
$input-bg-focus:                $color-lightest;

$input-color-placeholder:       $color-gray-500 !default;

$input-padding-x-sm:            1.1rem !default;
$input-padding-y-sm:            1.1rem !default;
$input-line-height-sm:          $input-line-height !default;

$input-padding-x-lg:            1.5rem !default;
$input-padding-y-lg:            2.3rem !default;
$input-line-height-lg:          $input-line-height !default;

$input-height:                  (($font-size-md * $input-line-height) + ($input-padding-y * 2)) !default;
$input-height-lg:               (($font-size-md * $input-line-height-lg) + ($input-padding-y-lg * 2)) !default;
$input-height-sm:               (($font-size-md * $input-line-height-sm) + ($input-padding-y-sm * 2)) !default;

$input-transition:              border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;

$form-text-margin-top:          .25rem !default;
$form-text-margin-bottom:       .5rem !default;
$form-feedback-margin-top:      $form-text-margin-top !default;

$form-check-margin-bottom:      .5rem !default;
$form-check-input-gutter:       2rem !default;
$form-check-input-margin-y:     .5rem !default;
$form-check-input-margin-x:     .25rem !default;

$form-check-inline-margin-x:    .75rem !default;

$form-group-margin-bottom:      1.6rem !default;
$form-group-margin-bottom-lg:      2.4rem !default;

$input-group-addon-color:        $color-gray-700 !default;
$input-group-addon-bg:           $color-white !default;
$input-group-addon-border-color: $input-border-color !default;

// Form validation icons
$form-icon-success-color: $color-success !default;
$form-icon-success: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-success-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E"), "#", "%23") !default;

$form-icon-warning-color: $color-warning !default;
$form-icon-warning: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-warning-color}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E"), "#", "%23") !default;

$form-icon-danger-color: $color-danger !default;
$form-icon-danger: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-icon-danger-color}' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E"), "#", "%23") !default;

/* CORE: Form states and alerts
========================================================== */
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text:            #3C763D !default;
$state-success-bg:              #DFF0D8 !default;
$state-success-border:          darken($state-success-bg, 5%) !default;

$state-info-text:               #31708F !default;
$state-info-bg:                 #D9EDF7 !default;
$state-info-border:             darken($state-info-bg, 7%) !default;

$state-warning-text:            #8A6D3B !default;
$state-warning-bg:              #FCF8E3 !default;
$mark-bg:                       $state-warning-bg !default;
$state-warning-border:          darken($state-warning-bg, 5%) !default;

$state-danger-text:             #A94442 !default;
$state-danger-bg:               #F2DEDE !default;
$state-danger-border:           darken($state-danger-bg, 5%) !default;

/* CORE: Icons
========================================================== */

$icon-size-default:             2.4rem;
$icon-size-lg-small:            4.8rem;
$icon-size-lg:                  8.4rem;
$rounded-icon-size:             4.8rem;
$icon-size-xl:                  150px;

/* CORE: Images & figures
========================================================== */

// Image thumbnails

$thumbnail-padding:             .25rem !default;
$thumbnail-bg:                  $color-body-bg !default;
$thumbnail-border-width:        $border-width !default;
$thumbnail-border-color:        #DDD !default;
$thumbnail-border-radius:       $border-radius !default;
$thumbnail-box-shadow:          0 1px 2px rgba($color-darkest,.075) !default;
$thumbnail-transition:          all .2s ease-in-out !default;

// Figures

$figure-caption-font-size:      90% !default;
$figure-caption-color:          $color-gray-light !default;

/* CORE: List group
========================================================== */

$list-group-color:              $color-body-text !default;
$list-group-bg:                 $color-lightest !default;
$list-group-border-color:       rgba($color-darkest,.125) !default;
$list-group-border-width:       $border-width !default;
$list-group-border-radius:      $border-radius !default;

$list-group-item-padding-x:     1.5rem !default;
$list-group-item-padding-y:     1rem !default;

$list-group-hover-bg:           $color-gray-lightest !default;
$list-group-active-color:       $component-active-color !default;
$list-group-active-bg:          $component-active-bg !default;
$list-group-active-border:      $list-group-active-bg !default;

$list-group-disabled-color:      $color-gray-light !default;
$list-group-disabled-bg:        $list-group-bg !default;

$list-group-link-color:         $color-gray !default;
$list-group-link-hover-color:   $list-group-link-color !default;

$list-group-link-active-color:  $color-lightest !default;
$list-group-link-active-bg:     $color-gray-lighter !default;

/* GRID: Breakpoints
========================================================== */
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


/* GRID: Containers
========================================================== */
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 100%,
    md: 720px,
    lg: 960px,
    xl: 1400px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


/* GRID: Columns
========================================================== */
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12 !default;
$grid-gutter-width-base:     30px !default;
$grid-gutter-widths: (
    xs: $grid-gutter-width-base,
    sm: $grid-gutter-width-base,
    md: $grid-gutter-width-base,
    lg: $grid-gutter-width-base,
    xl: $grid-gutter-width-base
) !default;



/* COMPONENT: Alerts
========================================================== */
//
// Define alert colors, border radius, and padding.

$alert-padding-x:           2rem !default;
$alert-padding-y:           2rem !default;
$alert-margin-bottom:       15px !default;
$alert-border-radius:       $border-radius !default;
$alert-link-font-weight:    $font-weight-bold !default;
$alert-border-width:        $border-width !default;

$alert-default-bg:          $color-default;
$alert-default-text:        $color-lightest;
$alert-default-border:      $color-default;

$alert-success-bg:          $color-green-lightest !default;
$alert-success-text:        $color-green-dark !default;
$alert-success-border:      $color-green-lightest !default;

$alert-info-bg:             $color-blue-lightest !default;
$alert-info-text:           $color-blue-dark !default;
$alert-info-border:         $color-blue-lightest !default;

$alert-warning-bg:          $color-orange-lightest !default;
$alert-warning-text:        $color-orange-dark !default;
$alert-warning-border:      $color-orange-lightest !default;

$alert-danger-bg:           $color-red-lightest !default;
$alert-danger-text:         $color-red-dark !default;
$alert-danger-border:       $color-red-lightest !default;


/* COMPONENT: Badge
========================================================== */
//
// Define alert colors, border radius, and padding.

$badge-default-bg:            $color-gray-400 !default;
$badge-color:                 $color-darkest !default;
$badge-font-size:             1.4rem !default;
$badge-font-weight:           $font-weight-bold !default;
$badge-padding-x:             1.2rem !default;
$badge-padding-y:             .8rem !default;
$badge-border-radius:         $border-radius !default;

$badge-colors: ();
$badge-colors: map-merge(("purple": (
        "color-lightest": $color-purple-lightest,
        "color-light": $color-purple-light,
        "color": $color-purple,
        "color-dark": $color-purple-dark,
    ), "violet": (
        "color-lightest": $color-violet-lightest,
        "color-light": $color-violet-light,
        "color": $color-violet,
        "color-dark": $color-violet-dark,
    ), "gray": (
        "color-lightest": $color-gray-100,
        "color-light": $color-gray-400,
        "color": $color-gray-600,
        "color-dark": $color-gray-800,
    ), "white": (
        "color-lightest": $color-white,
        "color-light": $color-white,
        "color": $color-white,
        "color-dark": $color-white,
    )
), $badge-colors);

/* COMPONENT: Box
========================================================== */
//
// Define alert colors, border radius, and padding.

$box-spacer-x:                  1.25rem !default;
$box-spacer-y:                  .75rem !default;

$box-border-width:              1px;
$box-border-radius:             6px;


$box-default-color:             $color-default-dark;
$box-default-bg:                $color-gray-lighter;
$box-default-border:            $border-default-color;


/* COMPONENT: Breadcrumb
========================================================== */
//
// Define alert colors, border radius, and padding.

$breadcrumb-font-size:          1.6rem;
$breadcrumb-padding-y:          1.2rem !default;
$breadcrumb-padding-x:          0 !default;
$breadcrumb-item-padding:       .8rem !default;
$breadcrumb-font-weight:        $font-weight-bold !default;

$breadcrumb-margin-bottom:      0 !default;

$breadcrumb-bg:                 $color-lightest !default;
$breadcrumb-active-color:       $color-gray-700 !default;
$breadcrumb-border-color:       $color-gray-300 !default;

/* COMPONENT: Card
========================================================== */

$card-spacer-x:             2.4rem !default;
$card-spacer-y:             2.4rem !default;
$card-border-width:         1px !default;
$card-border-radius:        $border-radius !default;
$card-border-color:         $border-default-color !default;
$card-border-radius-inner:  calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:               #EFEEED !default;
$card-bg:                   $color-lightest !default;

$card-header-font-size:     1.8rem !default;

$card-spacing-sm-x:         1rem !default;
$card-spacing-sm-y:         1rem !default;

$card-spacing-md-x:         .5rem !default;
$card-spacing-md-y:         .75rem !default;

$card-link-hover-color:     $color-lightest !default;

$card-img-overlay-padding:  1.25rem !default;

$card-deck-margin:          ($grid-gutter-width-base / 2) !default;

$card-columns-count:        3 !default;
$card-columns-gap:          1.25rem !default;
$card-columns-margin:       $card-spacer-y !default;


/* COMPONENT: CTA
========================================================== */

$cta-bg:                        $color-violet;
$cta-color:                     $color-lightest;
$cta-padding-x:                 2.4rem;
$cta-padding-y:                 2.4rem;
$cta-icon-padding:              9.4rem;

$cta-title-size:                1.8rem;
$cta-title-line-height:      2.4rem;
$cta-title-size-lg:             2rem;
$cta-title-line-height-lg:      2.6rem;

/* COMPONENT: Carousel
========================================================== */

$carousel-control-color:        $color-lightest !default;
$carousel-control-width:        15% !default;
$carousel-control-opacity:      .5 !default;

$carousel-indicator-width:      30px !default;
$carousel-indicator-height:     3px !default;
$carousel-indicator-spacer:     3px !default;
$carousel-indicator-active-bg:  $color-lightest !default;

$carousel-caption-color:        $color-lightest !default;
$carousel-caption-width:        70% !default;

$carousel-control-icon-width:   20px !default;

$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$carousel-transition:           transform .6s ease-in-out !default;

/* COMPONENT: Close
========================================================== */

$close-font-size:           $font-size-base * 1.5 !default;
$close-font-weight:         $font-weight-bold !default;
$close-text-shadow:         0 1px 0 $color-lightest !default;

$close-color:               $color-darkest !default;

/* COMPONENT: Compare
========================================================== */

$compare-border:            #E3E3E3;
$compare-bg:                $color-lightest;

/* COMPONENT: Datagrid
========================================================== */

$datagrid-border-color:     $color-default;
$datagrid-border-radius:    $border-radius !default;
$datagrid-header-bg:        $color-default;

/* COMPONENT: Header
========================================================== */

$header-bg:                 #CDCDCD;

/* COMPONENT: Hero
========================================================== */

$hero-padding:              10.4rem !default;
$hero-bg:                   $color-gray-lighter !default;


/* COMPONENT: Modal
========================================================== */

// Padding applied to the modal body
$modal-inner-padding:               2.4rem !default;

$modal-dialog-margin:               10px !default;
$modal-dialog-sm-up-margin-y:       30px !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-bg:                  $color-lightest !default;
$modal-content-border-color:        rgba($color-darkest,.2) !default;
$modal-content-border-width:        $border-width !default;
$modal-content-xs-box-shadow:       0 3px 9px rgba($color-darkest,.5) !default;
$modal-content-sm-up-box-shadow:    0 5px 15px rgba($color-darkest,.5) !default;

$modal-backdrop-bg:                 $color-purple !default;
$modal-backdrop-opacity:            .65 !default;
$modal-header-bg:                   $color-white;
$modal-header-border-color:         $color-gray-lighter !default;
$modal-footer-border-color:         $color-gray-300 !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding:              2.4rem !default;

$modal-lg:                          800px !default;
$modal-md:                          600px !default;
$modal-sm:                          300px !default;

$modal-transition:                  transform .3s ease-out !default;

/* COMPONENT: Nav
========================================================== */

$nav-link-padding:                          1.5rem !default;
$nav-disabled-link-color:                   $color-gray-light !default;

$nav-tabs-border-color:                     $color-gray-400 !default;
$nav-tabs-border-width:                     1px !default;
$nav-tabs-border-radius:                    $border-radius !default;
$nav-tabs-link-hover-border-color:          $color-gray-400 !default;
$nav-tabs-active-link-hover-color:          $color-gray-700 !default;
$nav-tabs-active-link-hover-bg:             $color-gray-200 !default;
$nav-tabs-active-link-hover-border-color:   $color-gray-400 !default;

$nav-pills-border-radius:                   $border-radius !default;
$nav-pills-active-link-color:               $component-active-color !default;
$nav-pills-active-link-bg:                  $component-active-bg !default;

/* COMPONENT: Navbar
========================================================== */

$navbar-padding-x:                  $spacer !default;
$navbar-padding-y:                  ($spacer / 2) !default;

$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;

$navbar-inverse-color:              rgba($color-lightest,.5) !default;
$navbar-inverse-hover-color:        rgba($color-lightest,.75) !default;
$navbar-inverse-active-color:       rgba($color-lightest,1) !default;
$navbar-inverse-disabled-color:     rgba($color-lightest,.25) !default;
$navbar-inverse-toggler-bg:         str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-inverse-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-inverse-toggler-border:     rgba($color-lightest,.1) !default;

$navbar-light-color:                rgba($color-darkest,.5) !default;
$navbar-light-hover-color:          rgba($color-darkest,.7) !default;
$navbar-light-active-color:         rgba($color-darkest,.9) !default;
$navbar-light-disabled-color:       rgba($color-darkest,.3) !default;
$navbar-light-toggler-bg:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border:       rgba($color-darkest,.1) !default;

$navbar-primary-color:              #777;
$navbar-primary-hover-color:        #777;
$navbar-primary-bg:                 #F8F8F8;
$navbar-primary-border:             #E7E7E7;

$navbar-default-color:              $color-gray-lighter;
$navbar-default-bg:                 $color-lightest;
$navbar-default-border:             $color-gray-lighter;

/* COMPONENT: Pagination
========================================================== */

$pagination-padding-x:              1.2rem !default;
$pagination-padding-y:              .8rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-lg:           1.2rem !default;
$pagination-padding-y-lg:           .8rem !default;
$pagination-line-height:            1.8rem !default;

$pagination-color:                  $color-gray-800 !default;
$pagination-bg:                     $color-lightest !default;
$pagination-border-width:           $border-width !default;
$pagination-border-color:           $color-gray-400 !default;

$pagination-hover-color:            $color-gray-800 !default;
$pagination-hover-bg:               $color-gray-100 !default;
$pagination-hover-border:           $color-gray-400 !default;

$pagination-active-color:           $color-lightest !default;
$pagination-active-bg:              $color-violet !default;
$pagination-active-border:          $color-violet !default;

$pagination-disabled-color:         $color-gray-800 !default;
$pagination-disabled-bg:            $color-gray-400 !default;
$pagination-disabled-border:        $color-gray-400 !default;

/* COMPONENT: Panel
========================================================== */

$panel-default-color:               #575757;
$panel-default-bg:                  $color-gray-lighter;
$panel-border-color:                $card-border-color;
$panel-padding-x:                   1.7rem;
$panel-padding-y:                   1.5rem;
$panel-border-radius:               .4rem;

/* COMPONENT: Popovers
========================================================== */

$popover-inner-padding:             1px !default;
$popover-bg:                        $color-lightest !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              rgba($color-darkest,.2) !default;
$popover-box-shadow:                0 5px 10px rgba($color-darkest,.2) !default;

$popover-title-bg:                  darken($popover-bg, 3%) !default;
$popover-title-padding-x:           14px !default;
$popover-title-padding-y:           8px !default;

$popover-content-padding-x:         14px !default;
$popover-content-padding-y:         9px !default;

$popover-arrow-width:               10px !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-width:         ($popover-arrow-width + 1px) !default;
$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;

/* COMPONENT: Progress bars
========================================================== */

$progress-height:                   1rem !default;
$progress-font-size:                .75rem !default;
$progress-bg:                       $color-gray-lighter !default;
$progress-border-radius:            $border-radius !default;
$progress-box-shadow:               inset 0 .1rem .1rem rgba($color-darkest,.1) !default;
$progress-bar-color:                $color-lightest !default;
$progress-bar-bg:                   $color-primary !default;
$progress-bar-animation-timing:     1s linear infinite !default;

/* COMPONENT: Tooltips
========================================================== */

$tooltip-max-width:                 230px !default;
$tooltip-color:                     $color-lightest !default;
$tooltip-bg:                        $color-secondary !default;
$tooltip-opacity:                   .9 !default;
$tooltip-padding-y:                 8px !default;
$tooltip-padding-x:                 10px !default;
$tooltip-margin:                    3px !default;

$tooltip-arrow-width:               6px !default;
$tooltip-arrow-color:               $tooltip-bg !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop:          990 !default;
$zindex-dropdown:                   1000 !default;
$zindex-fixed:                      1030 !default;
$zindex-sticky:                     1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;

