//
// radios
//

.radio {
    &.chk-only {
        display: block;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;

        .radio-label {
            position: absolute;
            top: 0;
            left: 0;
            width: 2rem;
            height: 2rem;
        }
    }
    &.disabled {
        cursor: no-drop;
        opacity: .7;
        small {
            color: #555;
        }
    }
    // normal chebkox-input
    .radio-input {
        position: absolute;
        left: -99999px;
    }

    label, .radio-label {
        a { text-decoration: underline; }
    }

    // styled radio-input
    label::before {
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
        width: 17px;
        height: 17px;
        margin: 0 .8rem 0 0;
        content: '';
        background-color: $color-lightest;
        border: 1px solid $hr-border-color;
        @include border-radius(100%);
    }

    input[type="radio"] + label {
        position: relative;
        clear: none;
        margin: 0 0 .5rem 0;
        padding-left: 25px;
        cursor: pointer;
    }

    input[type="radio"]:checked + label {
        clear: none;
        margin: 0 0 .5rem 0;
        cursor: pointer;

        &:before {
            @include sprite();
            background-color: $color-lightest;
            border: 5px solid $color-purple;
        }
    }

    input[type="radio"]:disabled:checked + label {
        color: $input-color-disabled;
        cursor: not-allowed;
        pointer-events: none;

        &:before {
            @include sprite();
            background-color: $color-lightest;
            background-position: -243px -242px;
            border: 5px solid $input-color-disabled ;
            cursor: not-allowed;
            &:hover {
                cursor: not-allowed;
            }
        }
        &:hover {
            cursor: not-allowed;
        }
    }

    input[type="radio"]:disabled + label {
        color: $input-color-disabled;
        cursor: not-allowed;
        pointer-events: none;

        &:before {
            background-color: $color-lightest;
            background-image: none;
            border-color: $input-color-disabled;
            cursor: not-allowed;
            &:hover {
                cursor: not-allowed;
            }
        }
        &:hover {
            cursor: not-allowed;
        }
    }

    .has-error &, &.has-error & {

        input[type="radio"] + label {
            color: $color-danger;
            &:before { border-color: $color-danger; }
        }

    }
}


label.radio-label:focus, label.radio-label.focussed {
    outline: 0;
    &:before {
        -webkit-box-shadow: $btn-focus-box-shadow;
        -moz-box-shadow: $btn-focus-box-shadow;
        box-shadow: $btn-focus-box-shadow;
    }
}
