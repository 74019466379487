// Card variants

@mixin card-variant($color, $background, $border) {
    background-color: $background;
    border-color: $border;

    .card-header {
        margin: $card-spacer-y $card-spacer-x 0;
        padding: 0;
        padding-bottom: $card-spacer-y;
        color: $color;
        border-color: $border;
    }

    .card-header,
    .card-footer {
        background-color: transparent;
    }
}

@mixin card-spacing-variant($spacing-x, $spacing-y) {
    .card-block,
    .card-footer {
        padding: $spacing-y $spacing-x;
    }
}

@mixin card-outline-variant($color, $background, $border) {
    background-color: $color-lightest;
    border-color: $border;

    .card-header {
        color: $color;
        background-color: $background;
        border-color: $border;
    }
}

//
// Inverse text within a card for use with dark backgrounds
//

@mixin card-inverse {
    color: rgba(255,255,255,.65);

    .card-header,
    .card-footer {
        background-color: transparent;
        border-color: rgba(255,255,255,.2);
    }
    .card-header,
    .card-footer,
    .card-title,
    .card-blockquote {
        color: $color-lightest;
    }
    .card-link,
    .card-text,
    .card-subtitle,
    .card-blockquote .blockquote-footer {
        color: rgba(255,255,255,.65);
    }
    .card-link {
        @include hover-focus {
            color: $card-link-hover-color;
        }
    }
}