/*
Reboot

Part of this frameworks' job is to provide an elegant, consistent, and simple baseline to build upon. We use Reboot, a collection of element-specific CSS changes in a single file, to kickstart that.
It adds global resets to common HTML elements and implements parts of Normalize.css (v5.0.0).

Markup: docs/core/reboot.hbs

Style guide: Core.reboot
*/

// Document
// ==========================================================================

html {
    // Change the default font family in all browsers (opinionated).
    font-family: sans-serif;

    // Set the base font-size
    font-size: $font-scale-base;

    // Correct the line height in all browsers.
    line-height: 1.15; // 2

    // Prevent adjustments of font size after orientation changes in
    -ms-text-size-adjust: 100%; // 3
    -webkit-text-size-adjust: 100%; // 3

    // Reset the box-sizing
    //
    // Change from `box-sizing: content-box` to `border-box` so that when you add
    // `padding` or `border`s to an element, the overall declared `width` does not
    // change. For example, `width: 100px;` will always be `100px` despite the
    // `border: 10px solid red;` and `padding: 20px;`.
    //
    // Heads up! This reset may cause conflicts with some third-party widgets. For
    // recommendations on resolving such conflicts, see
    // https://getbootstrap.com/getting-started/#third-box-sizing.
    //
    // Credit: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
    box-sizing: border-box;

    // We assume no initial pixel `font-size` for accessibility reasons. This
    // allows web visitors to customize their browser default font-size, making
    // your project more inclusive and accessible to everyone.

    // As a side-effect of setting the @viewport above,
    // IE11 & Edge make the scrollbar overlap the content and automatically hide itself when not in use.
    // Unfortunately, the auto-showing of the scrollbar is sometimes too sensitive,
    // thus making it hard to click on stuff near the right edge of the page.
    // So we add this style to force IE11 & Edge to use a "normal", non-overlapping, non-auto-hiding scrollbar.
    // See https://github.com/twbs/bootstrap/issues/18543
    // and https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7165383/
    -ms-overflow-style: scrollbar;

    // Changes the default tap highlight to be completely transparent in iOS.
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

*,
*::before,
*::after {
    box-sizing: inherit;
}



// Make viewport responsive
//
// @viewport is needed because IE 10+ doesn't honor <meta name="viewport"> in
// some cases. See https://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/.
// Eventually @viewport will replace <meta name="viewport">.
//
// However, `device-width` is broken on IE 10 on Windows (Phone) 8,
// (see https://timkadlec.com/2013/01/windows-phone-8-and-device-width/ and https://github.com/twbs/bootstrap/issues/10497)
// and the fix for that involves a snippet of JavaScript to sniff the user agent
// and apply some conditional CSS.
//
// See https://getbootstrap.com/getting-started/#support-ie10-width for the relevant hack.
//
// Wrap `@viewport` with `@at-root` for when folks do a nested import (e.g.,
// `.class-name { @import "bootstrap"; }`).
@at-root {
    @-ms-viewport { width: device-width; }
}

// Sections
// ==========================================================================

body {
    // Fixed footer
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    // Remove the margin in all browsers (opinionated).
    margin: 0;

    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;

    // Go easy on the eyes and use something other than `#000` for text
    color: $color-body-text;
    // By default, `<body>` has no `background-color` so we set one as a best practice.
    background-color: $color-body-bg;
}

//
// Add the correct display in IE 9-.
//

article,
aside,
footer,
header,
nav,
section {
    display: block;
}

// Grouping content
// ==========================================================================

figcaption,
figure,
main {
    // Add the correct display in IE 9-.
    // 1. Add the correct display in IE.
    display: block;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
    outline: none !important;
}

//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.
//

hr {
    height: 0; // 1
    overflow: visible; // 2
    box-sizing: content-box; // 1
}

//
// Typography
//

// Remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.
p {
    margin-top: 0;
    margin-bottom: 2.4rem;
}

abbr[title] {
    // Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
    text-decoration: none;

    // Remove the bottom border in Firefox 39-.
    border-bottom: none;
}

// Abbreviations
abbr[title],
// Add data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257
abbr[data-original-title] {
    cursor: pointer;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

b,
strong {
    // Prevent the duplicate application of `bolder` by the next rule in Safari 6.
    font-weight: inherit;
}

b,
strong {
    // Add the correct font weight in Chrome, Edge, and Safari.
    font-weight: bolder;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: $font-weight-dt;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0; // Undo browser default
}

blockquote {
    margin: 0 0 1rem;
}

//
// Links
//

a {
    text-decoration: $decoration-link;
    color: $color-link;
    // Remove the gray background on active links in IE 10.
    background-color: transparent; // 1
    // Remove gaps in links underline in iOS 8+ and Safari 8+.
    -webkit-text-decoration-skip: objects; // 2
    font-weight: $font-weight-bold;
    transition: color .32s;
    @include hover {
        text-decoration: $decoration-link-hover;
        color: $color-link-hover;
    }
}

// Remove the outline on focused links when they are also active or hovered
// in all browsers (opinionated).

a:active,
a:hover {
    outline-width: 0;
}

// And undo these styles for placeholder links/named anchors (without href)
// which have not been made explicitly keyboard-focusable (without tabindex).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]):not([tabindex]) {
    text-decoration: none;
    color: inherit;

    @include hover-focus {
        text-decoration: none;
        color: inherit;
    }

    &:focus {
        outline: 0;
    }
}


//
// Code
//

code,
kbd,
samp,
pre {
    // Correct the inheritance and scaling of font size in all browsers.
    font-family: monospace, monospace;
    // Correct the odd `em` font sizing in all browsers.
    font-size: 1em;
}

pre {
    // Remove browser default top margin
    margin-top: 0;
    // Reset browser default of `1em` to use `rem`s
    margin-bottom: 1rem;
    // Normalize v4 removed this property, causing `<pre>` content to break out of wrapping code snippets
    overflow: auto;
}

//
// Add the correct font style in Android 4.3-.
//

dfn {
    font-style: italic;
}

//
// Add the correct background and color in IE 9-.
//

mark {
    color: $color-darkest;
    background-color: #FF0;
}

//
// Add the correct font size in all browsers.
//

small {
    font-size: 80%;
}

//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
//

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

// Embedded content
// ==========================================================================

//
// Add the correct display in IE 9-.
//

audio,
video {
    display: inline-block;
}

//
// Add the correct display in iOS 4-7.
//

audio:not([controls]) {
    display: none;
    height: 0;
}

//
// Hide the overflow in IE.
//

svg:not(:root) {
    overflow: hidden;
}

//
// Figures
//

figure {
    // Normalize adds `margin` to `figure`s as browsers apply it inconsistently.
    // We reset that to create a better flow in-page.
    margin: 0 0 1rem;
}

//
// Images
//

img {
    // Remove the border on images inside links in IE 10-.
    border-style: none;

    // By default, `<img>`s are `inline-block`. This assumes that, and vertically
    // centers them. This won't apply should you reset them to `block` level.
    vertical-align: middle;
    // Note: `<img>`s are deliberately not made responsive by default.
    // For the rationale behind this, see the comments on the `.img-fluid` class.
}


// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
    cursor: pointer;
}


// Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
//
// In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11
// DON'T remove the click delay when `<meta name="viewport" content="width=device-width">` is present.
// However, they DO support removing the click delay via `touch-action: manipulation`.
// See:
// * https://v4-alpha.getbootstrap.com/content/reboot/#click-delay-optimization-for-touch
// * http://caniuse.com/#feat=css-touch-action
// * https://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
    touch-action: manipulation;
}


//
// Tables
//

table {
    // Reset for nesting within parents with `background-color`.
    background-color: $table-bg;
    // No longer part of Normalize since v4
    border-collapse: collapse;
}

caption {
    padding-top: $table-cell-padding;
    padding-bottom: $table-cell-padding;
    text-align: left;
    color: $color-text-muted;
    caption-side: bottom;
}

th {
    // Centered by default, but left-align-ed to match the `td`s below.
    text-align: left;
}


// Forms
// ==========================================================================

form {
    margin: 0;
}

button,
input,
optgroup,
select,
textarea {
    // Remove the margin in Firefox and Safari.
    margin: 0;

    // Change the font styles in all browsers (opinionated).
    font-family: $font-family-sans-serif;
    font-size: 100%;
    line-height: 1.15;
}

input[type="file"] {
    font-size: 11px;
}

button,
input {
    // Show the overflow in Edge.
    overflow: visible;
}

button,
select {
    // Remove the inheritance of text transform in Edge, Firefox, and IE.
    text-transform: none;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
//

button,
html [type="button"], // 1
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // 2
}

// Remove the inner border and padding in Firefox.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

// Restore the focus styles unset by the previous rule.
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

label {
    // Allow labels to use `margin` for spacing.
    display: inline-block;
    margin-bottom: .6rem;
    font-weight: 600;
}

// Work around a Firefox/IE bug where the transparent `button` background
// results in a loss of the default `button` focus styles.
//
// Credit: https://github.com/suitcss/base/
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
textarea {
    // Normalize includes `font: inherit;`, so `font-family`. `font-size`, etc are
    // properly inherited. However, `line-height` isn't inherited there.
    line-height: inherit;
}

//
// 1. Remove the padding in IE 10-.
// 2. Add the correct box sizing in IE 10-.
//

[type="checkbox"],
[type="radio"] {
    padding: 0; // 1
    box-sizing: border-box; // 2
}

//
// Correct the cursor style of increment and decrement buttons in Chrome.
//

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

//
// 1. Correct the odd appearance in Chrome and Safari.
// 2. Correct the outline style in Safari.
//

[type="search"] {
    outline-offset: -2px; // 2
    -webkit-appearance: textfield; // 1
}

//
// Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
//

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

//
// 1. Change font properties to `inherit` in Safari.
// 2. Correct the inability to style clickable types in iOS and Safari.
//

::-webkit-file-upload-button {
    font: inherit; // 1
    -webkit-appearance: button; // 2
}

input[type="radio"],
input[type="checkbox"] {
    // Apply a disabled cursor for radios and checkboxes.
    //
    // Note: Neither radios nor checkboxes can be readonly.
    &:disabled {
        cursor: $cursor-disabled;
    }
}


input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    // Remove the default appearance of temporal inputs to avoid a Mobile Safari
    // bug where setting a custom line-height prevents text from being vertically
    // centered within the input.
    // See https://bugs.webkit.org/show_bug.cgi?id=139848
    // and https://github.com/twbs/bootstrap/issues/11266
    -webkit-appearance: listbox;
}

textarea {
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    min-height: 100px;
    resize: vertical;
    overflow: auto;
}

fieldset {
    // Browsers set a default `min-width: min-content;` on fieldsets,
    // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
    // So we reset that to ensure fieldsets behave more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359
    // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
    min-width: 0;
    // Reset the default outline behavior of fieldsets so they don't affect page layout.
    margin: 0;
    padding: 0;
    border: 0;
}

// 1. Correct the text wrapping in Edge and IE.

legend {
    // Reset the entire legend element to match the `fieldset`
    display: block;
    width: 100%;
    max-width: 100%; // 1
    margin-bottom: .5rem;
    padding: 0;
    font-size: 1.5rem;
    line-height: inherit;

    // Correct the color inheritance from `fieldset` elements in IE.
    color: inherit;
    white-space: normal; // 1
    box-sizing: border-box; // 1
}

input[type="search"] {
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    -webkit-appearance: none;
}

//
// 1. Add the correct display in IE 9-.
// 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
//

progress {
    display: inline-block; // 1
    vertical-align: baseline; // 2
}

// todo: needed?
output {
    display: inline-block;
    //  font-size: $font-size-base;
    //  line-height: $line-height;
    //  color: $input-color;
}

// Interactive
// ==========================================================================

//
// Add the correct display in IE 9-.
// 1. Add the correct display in Edge, IE, and Firefox.
//

details, // 1
menu {
    display: block;
}

//
// Add the correct display in all browsers.
//

summary {
    display: list-item;
}

// Scripting
// ==========================================================================

//
// Add the correct display in IE 9-.
//

canvas {
    display: inline-block;
}

//
// Add the correct display in IE.
//

template {
    display: none;
}

// Hidden
// ==========================================================================

//
// Add the correct display in IE 10-.
// Always hide an element with the `hidden` HTML attribute (from PureCSS).

[hidden] {
    display: none !important;
}
