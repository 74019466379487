/*
Images

Opt your images into responsive behavior (so they never become larger than their parent elements) and add lightweight styles to them—all via classes.

Markup: docs/core/images.hbs

Style guide: Core.images
*/

// Responsive images (ensure images don't scale beyond their parents)
//
// This is purposefully opt-in via an explicit class rather than being the default for all `<img>`s.
// We previously tried the "images are responsive by default" approach in Bootstrap v2,
// and abandoned it in Bootstrap v3 because it breaks lots of third-party widgets (including Google Maps)
// which weren't expecting the images within themselves to be involuntarily resized.
// See also https://github.com/twbs/bootstrap/issues/18178
.img-fluid {
    @include img-fluid;
}


// Image thumbnails
.img-thumbnail {
    padding: $thumbnail-padding;
    background-color: $thumbnail-bg;
    border: $thumbnail-border-width solid $thumbnail-border-color;
    @include border-radius($thumbnail-border-radius);
    @include transition($thumbnail-transition);
    @include box-shadow($thumbnail-box-shadow);

    // Keep them at most 100% wide
    @include img-fluid;
}

.img-rounded {
    border-radius: $border-radius;
}

//
// Alignment
//

.img-left {
    float: left;
    margin-bottom: $spacer-y;
    margin-right: $spacer-x * 2;
}

.img-right {
    float: right;
    margin-bottom: $spacer-y;
    margin-left: $spacer-x * 2;
}
