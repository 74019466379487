/*
Screenreader

Use .d-block, .d-inline, or .d-inline-block to simply set an element’s display property to block, inline, or inline-block (respectively).

Markup: docs/utilities/display.hbs

Style guide: Utilities.display
*/

.sr-only {
    @include sr-only();
}

.sr-only-focusable {
    @include sr-only-focusable();
}