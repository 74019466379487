@mixin sprite() {
    background-image: url(../assets/svg/sprite.svg);
    background-repeat: no-repeat;
    background-size: 3540px 1000px;
}

/*
@mixin visuals() {
    background-image: url(../img/visuals.svg);
    background-repeat: no-repeat;
    background-size: 833px 470px;
}
*/