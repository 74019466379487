.wizard {
    padding: 4.8rem 0 7.2rem;
    @include media-breakpoint-up(md) {
        padding: 7.2rem 0 9.6rem;
    }
}

.wizard-header {
    position: relative;
    padding-bottom: 2.4rem;
}

.wizard-step {
    position: relative;
    max-height: 0;
    opacity: 0;
    transition: opacity .2s ease-out;
    transition-delay: 0s;
    height: 0;
    overflow: hidden;
    &::before {
        position: absolute;
        top: 1.8rem;
        left: 50%;
        width: 2px;
        height: 40px;
        background: $color-violet;
        transform: translateX(-50%);
        content: '';
    }
    &:first-child {
        padding-top: 0 !important;
        &::before {
            display: none;
        }
    }
    &.active {
        height: auto;
        padding-top: 9rem;
        opacity: 1;
        max-height: 10000px;
        transition: opacity .7s ease-out;
        transition-delay: .2s;
    }
    .wizard-step-content {
        max-height: 1000px;
        opacity: 1;
        transition: opacity .3s ease-out;
    }
    .chosen-info {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%);
        transition: opacity .3s ease-out;
        transition-delay: 0s;
    }
    &.done {
        .wizard-step-content {
            max-height: 0;
            opacity: 0;
        }
        .chosen-info {
            opacity: 1;
            pointer-events: auto;
            transition-delay: .2s;
        }
    }
}

.wizard-choice, .wizard-choice-link {
    padding: 2.6rem 2rem 3.2rem;
    border: 2px solid $color-violet;
    @include border-radius($border-radius);
    cursor: pointer;
    min-height: 100%;
    @include media-breakpoint-down(sm) {
        padding: 2.4rem;
    }
    .icon {
        margin-bottom: 1.4rem;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.ean-box {
    border-top: 1px solid $color-gray-400;
    border-bottom: 1px solid $color-gray-400;
    background: $color-gray-100;
    border-radius: 0;
}

.verify-streets {
    padding: 4.8rem 0 7.2rem;
    @include media-breakpoint-up(md) {
        padding: 7.2rem 0 9.6rem;
    }
}
