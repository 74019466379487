.responsive-calendar .controls {
    text-align: center;
    .icon {
        top: 1px;
    }
}
.responsive-calendar .controls a {
    cursor: pointer;
}
.responsive-calendar .controls h4 {
    display: inline;
    font-size: 1.6rem;
}
.responsive-calendar .day-headers,
.responsive-calendar .days {
    font-size: 0;
}
.responsive-calendar .day {
    display: inline-block;
    position: relative;
    font-size: 14px;
    width: 14.285714285714286%;
    text-align: center;
}
.responsive-calendar .day a {
    color: $border-default-color;
    display: block;
    margin: 0 auto;
    cursor: default;
    //padding: 13% 0 13% 0;
    font-size: 1.7rem;
    width: 40px;
    height: 40px;
    padding-top: 9px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;

}
.responsive-calendar .day a:hover {
    text-decoration: none;
    color: $border-default-color;
}
.responsive-calendar .day.header {
    color: $color-gray-700;
    font-family: $font-family-titles;
    font-size: 1.7rem;
    font-weight: 400;
}
.responsive-calendar .day.active a {
    cursor: pointer;
    //font-weight: 700;
    color: $color-body-text;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 50%;
}
.responsive-calendar .day.active a:hover {
    background-color: $color-gray-lightest;
}

.responsive-calendar .day.active a.active {
    background-color: $color-primary;
    color: $color-lightest;
}

.responsive-calendar .day.active .not-current {
    background-color: $color-lightest;
    color: $color-lightest;
}
.responsive-calendar .day.active .not-current:hover {
    //background-color: #bcdff5;
}
.responsive-calendar .day.not-current a {
    background-color: $color-lightest;
    color: $color-lightest;
}
.responsive-calendar .day .badge {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 1;
}

.responsive-calendar .pull-left {
    float: left;
}

.responsive-calendar .pull-right {
    float: right;
}

.responsive-calendar hr {
    border-color: #DBD8D6;
}

.timeslots {
    position: relative;
    min-height: 200px;
    @include media-breakpoint-up(md) {
        min-height: 269px;
    }
    @include media-breakpoint-up(lg) {
        min-height: 257px;
    }
    @include media-breakpoint-up(xl) {
        min-height: 275px;
    }
    .center {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .timeslot {
        margin: -1px -1px;
        border: 1px solid transparent;
        border-top: 1px solid $color-gray-400;
        transition: border-color .24s;
        .radio {
            display: block;
            width: 100%;
            height: 100%;
            label {
                display: block;
                padding: 2.4rem 2.4rem 2.4rem 4.8rem;
                &::before {
                    top: 50%;
                    left: 2.4rem;
                    transform: translateY(-50%);
                }
            }
        }
        &:first-child {
            border-top-color: transparent;
        }
        &.active {
            position: relative;
            border: 1px solid $color-violet;
            z-index: 2;
        }
    }
}

.dayslot {
    position: relative;
    padding: 15px 40px 15px 15px;
    border-top: 1px solid $border-default-color;
    font-weight: 500;
    &:first-child {
        border: none;
    }
    .remove-date {
        position: absolute;
        right: 15px;
        top: 13px;
        color: $color-gray-600;
    }
}

.days-empty-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
